import { Dialog, DialogContent, DialogContentText, DialogTitle } from "@mui/material";
import * as React from 'react';
import {
    AckPolicy,
    DeliverPolicy,
    nanos,
} from "nats.ws";
import Ajv from "ajv";
import addFormats from "ajv-formats";
import JSONSchemaService from "../services/JSONSchemaService";
import { dataSetsFromJSON } from "../domain/DataSet";
import NATSService from "../services/NATSService";
import { LogEntry } from "../domain/LogEntry";


interface LogViewDialogProps {
    id: string;
    name: string;
    open: boolean;
    onClose: () => void;
}

const MAX_LOG_LINES = 2000;

export default function LogViewDialog({ id, name, open, onClose }: LogViewDialogProps) {
    const [logEntries, setLogEntries] = React.useState<LogEntry[]>([]);

    React.useEffect(() => {
        if (!open) {
            return;
        }
        setLogEntries([]);

        NATSService.getNATSConnection()
            .then((nc) => {
                console.log("connected to NATS");
                return Promise.all([nc.jetstreamManager().then((jsm) => {
                    return jsm.consumers.add("data", {
                        ack_policy: AckPolicy.None,
                        name: id + new Date().getTime(),
                        inactive_threshold: nanos(2 * 60 * 1000), // given in nanos? => 2min
                        deliver_policy: DeliverPolicy.All,
                        filter_subject: `data.connections.*.${id}.*.*.cloud.iot-data.core.log.*`,
                    });
                }), nc.jetstream()]);
            }).then((res) => {
                return Promise.all([res[1].consumers.get("data", res[0].config.name), JSONSchemaService.getJSONSchema("https://iot-data.cloud/basic.data.schema")]);
            }).then((res) => {
                const c = res[0];
                const schema = res[1];
                return c.consume().then(async (messages) => {
                    const ajv = new Ajv();
                    addFormats(ajv);

                    for await (const m of messages) {
                        try {
                            const data = JSON.parse(new TextDecoder().decode(m.data));
                            const valid = ajv.validate(schema?.jsonSchema, data);
                            if (!valid) {
                                console.error("invalid data received: ", ajv.errors);
                                continue;
                            }

                            const dataSets = dataSetsFromJSON(data);

                            const logEntries = dataSets.map((ds) => {
                                const logEntry: LogEntry = {
                                    message: ds.data.message.value as string,
                                    module: ds.data.module.value as string,
                                    severity: ds.data.severity.value as any,
                                    time: ds.time,
                                };
                                return logEntry;
                            });

                            setLogEntries((old) => {
                                return [...old, ...logEntries].slice(-MAX_LOG_LINES);
                            });
                        } catch (error) {
                            console.log(error);
                        }
                    }
                });
            }).catch((err) => {
                console.error(err);
            });

        return () => {
        }
    }, [open, id])

    const handleClose = () => {
        onClose();
    };

    return <Dialog onClose={handleClose} open={open} fullWidth={true}>
        <DialogTitle>Logs of {name}</DialogTitle>
        <DialogContent>
            <DialogContentText>
            </DialogContentText>
            {
                logEntries.map((entry, index) => {
                    return <div key={index}>
                        <span style={{ color: entry.severity === "ERROR" ? "red" : "black" }}>{entry.message}</span>
                    </div>
                })
            }
        </DialogContent>
    </Dialog>
}