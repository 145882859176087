import { createContext, useContext, useMemo } from "react";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { User } from "../../domain/User";
import pb from "../PocketBase";
import { useLocalStorage } from "./useLocalStorage";
import { RecordOptions } from "pocketbase";
const AuthContext = createContext<{
    user: User | null,
    login: (username: string, password: string) => Promise<void>,
    loginWithOauth2: (provider: string) => Promise<void>,
    logout: () => Promise<void>
}>({
    user: null,
    login: () => { return Promise.reject() },
    loginWithOauth2: () => { return Promise.reject() },
    logout: () => { return Promise.reject() }
});

export const AuthProvider = ({ children }: { children: JSX.Element }) => {
    const [user, setUser] = useLocalStorage("user", null);
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();

    // call this function when you want to authenticate the user
    const login = async (username: string, password: string) => {
        return pb.collection("users").authWithPassword(username, password, {
            expand: "credentials",
        } as RecordOptions)
            .then((resp) => {
                setUser(resp.record);
                localStorage.setItem("iot_core_user_meta", JSON.stringify(resp.meta));
                if (searchParams.has("redirect")) {
                    window.location.href = searchParams.get("redirect") as string;
                    return;
                }
                navigate("/");
            });
    };

    const loginWithOauth2 = async (provider: string) => {
        return pb.collection('users').authWithOAuth2({ provider: provider })
            .then((resp) => {
                setUser(resp.record);
                localStorage.setItem("iot_core_user_meta", JSON.stringify(resp.meta));
                if (searchParams.has("redirect")) {
                    window.location.href = searchParams.get("redirect") as string;
                    return;
                }
                navigate("/");
            });
    };

    // call this function to sign out logged in user
    const logout = () => {
        setUser(null);
        navigate("/login", { replace: true });
        return Promise.resolve();
    };

    const value = useMemo(
        () => ({
            user,
            login,
            loginWithOauth2,
            logout
        }),
        [user]
    );
    return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

export const useAuth = () => {
    return useContext(AuthContext);
};