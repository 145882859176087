import SettingsIcon from '@mui/icons-material/Settings';
import HomeIcon from '@mui/icons-material/Home';
import { NavItem } from '../domain/NavItem';
import Config from '../pages/Config';
import Home from '../pages/Home';
import NewConnection from '../pages/NewConnection';
import DelegatedConfig from '../pages/DelegatedConfig';
import DelegatedNewConnection from '../pages/DelegatedNewConnection';
import IAM from '../pages/IAM';

export const Pages: NavItem[] = [{
  routes: ["home"],
  icon: "home",
  page: <Home />,
  text: "Home"
},
{
  routes: ["config/components", "config/components/:id"],
  icon: "lan",
  page: <Config />,
  text: "Connections",
  description: "Configure your iot-data.cloud connections"
},
{
  routes: ["config/iam", "config/iam/:entity"],
  icon: "group",
  page: <IAM />,
  text: "IAM",
  description: "Manage access to your iot-data.cloud instance"
},
{
  routes: ["config/delegated/:delegateID/components", "config/delegated/:delegateID/components/:id"],
  icon: "settings",
  page: <DelegatedConfig />,
  text: "Config",
  description: "Configure your delegated iot-data.cloud instances",
  internal: true
},
{
  routes: ["config/components/:id/connections/:type/new"],
  icon: "settings",
  page: <NewConnection />,
  text: "New Connection",
  description: "Adds a new connection",
  internal: true
},
{
  routes: ["config/delegated/:delegateID/components/:id/connections/:type/new"],
  icon: "settings",
  page: <DelegatedNewConnection />,
  text: "New Connection",
  description: "Adds a new connection at delegated instance",
  internal: true
}];