import { RecordModel } from "pocketbase";
import { ConfigSchema } from "../domain/ConfigSchema";

const ConfigSchemaService = {
    transformRecord: (record: RecordModel) => {
        let res: ConfigSchema = {
            id: record.id,
            name: record["name"],
            configSchema: record["config_schema"]
        }
        return res;
    },
}

export default ConfigSchemaService;