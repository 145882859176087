import * as React from 'react';
import { alpha, styled } from '@mui/material/styles';
import MuiAppBar, { AppBarProps as MuiAppBarProps } from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import Badge from '@mui/material/Badge';
import PersonIcon from '@mui/icons-material/Person';
import GroupIcon from '@mui/icons-material/Group';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { NavItem } from '../domain/NavItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import { Link, useLocation } from 'react-router-dom';
import Menu, { MenuProps } from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import LogoutIcon from '@mui/icons-material/Logout';
import AppsIcon from '@mui/icons-material/Apps';
import { useAuth } from '../services/auth/useAuth';
import { Box } from '@mui/material';
import TeamService from '../services/TeamService';
import { Team } from '../domain/Team';


const drawerWidth: number = 240;

interface AppBarProps extends MuiAppBarProps {
    open?: boolean;
}

const StyledMenu = styled((props: MenuProps) => (
    <Menu
        elevation={0}
        anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
        }}
        transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
        }}
        {...props}
    />
))(({ theme }) => ({
    '& .MuiPaper-root': {
        borderRadius: 6,
        marginTop: theme.spacing(1),
        minWidth: 180,
        backgroundColor: "#404154",
        color:
            theme.palette.mode === 'light' ? 'rgb(55, 65, 81)' : theme.palette.grey[300],
        boxShadow:
            'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
        '& .MuiMenu-list': {
            padding: '4px 0',
        },
        // '& .MuiMenuItem-root': {
        //     '& .MuiSvgIcon-root': {
        //         fontSize: 18,
        //         color: theme.palette.text.secondary,
        //         marginRight: theme.spacing(1.5),
        //     },
        //     '&:active': {
        //         backgroundColor: alpha(
        //             theme.palette.primary.main,
        //             theme.palette.action.selectedOpacity,
        //         ),
        //     },
        // },
    },
}));

const AppBar = styled(MuiAppBar, {
    shouldForwardProp: (prop) => prop !== 'open',
})<AppBarProps>(({ theme, open }) => ({
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    // backgroundColor: "#ffffff",
    // color: "#000",
    ...(open && {
        marginLeft: drawerWidth,
        width: `calc(100% - ${drawerWidth}px)`,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        })
    }),
}));

export default function Nav({ mainNavItems }: { mainNavItems: NavItem[] }) {

    const [accountMenuAnchorEl, setAccountMenuAnchorEl] = React.useState<null | HTMLElement>(null);
    const accountMenuOpen = Boolean(accountMenuAnchorEl);
    const handleAccountMenuClick = (event: React.MouseEvent<HTMLElement>) => {
        setAccountMenuAnchorEl(event.currentTarget);
    };
    const handleAccountMenuClose = () => {
        setAccountMenuAnchorEl(null);
    };

    const loc = useLocation();

    let toolbarText = "iot-data.cloud";
    let filtered = mainNavItems.filter((navItem) => {
        var matches = navItem.routes.filter((route) => {
            return loc.pathname === "/" + route;
        });
        return matches.length > 0;
    });
    toolbarText = filtered.length > 0 ? filtered[0].text : "iot-data.cloud";

    const auth = useAuth();
    const [myTeams, setMyTeams] = React.useState<Team[] | undefined>(undefined);
    const [myPrimaryTeam, setMyPrimaryTeam] = React.useState<Team | undefined>(undefined);

    React.useEffect(() => {
        if (auth.user === undefined || auth.user === null) {
            return;
        }
        TeamService.myTeams(auth.user.id).then((res) => {
            res.sort((a, b) => {
                const nameA = a.name.toUpperCase(); // ignore upper and lowercase
                const nameB = b.name.toUpperCase(); // ignore upper and lowercase
                if (nameA < nameB) {
                    return -1;
                }
                if (nameA > nameB) {
                    return 1;
                }

                // names must be equal
                return 0;
            });
            setMyTeams(res);
            var externalTeams = res.filter((t) => {
                return t.external === true;
            });
            if (externalTeams.length > 0) {
                setMyPrimaryTeam(externalTeams[0]);
                return
            }
            if (res.length > 0) {
                setMyPrimaryTeam(res[0]);
                return
            }
            setMyPrimaryTeam(undefined);
        });
    }, [auth]);

    return <div>
        <AppBar position="absolute" style={{
            //backgroundColor: "#ffffff",
        }} elevation={0}>
            <Toolbar
                sx={{
                    pr: '24px', // keep right padding when drawer closed
                }}
            >
                <Link to={"/"} style={{ color: "white" }}>
                    <IconButton>
                        <AppsIcon />
                    </IconButton>
                </Link>

                <Box sx={{ flexGrow: 1, marginLeft: "8px" }} component="div">
                    <Typography
                        component="h1"
                        variant="h6"
                        color="inherit"
                        noWrap
                    >
                        iot-data.cloud
                    </Typography>
                    <Typography
                        variant="caption" display="block" gutterBottom>
                        {toolbarText}
                    </Typography>
                </Box>
                {/* <IconButton color="inherit">
                    <Badge badgeContent={4} color="secondary">
                        <NotificationsIcon />
                    </Badge>
                </IconButton> */}
                <IconButton color="inherit" onClick={handleAccountMenuClick}>
                    <Badge color="secondary">
                        <PersonIcon />
                    </Badge>
                </IconButton>
            </Toolbar>
        </AppBar>

        <StyledMenu
            id="demo-customized-menu"
            MenuListProps={{
                'aria-labelledby': 'demo-customized-button',
            }}
            anchorEl={accountMenuAnchorEl}
            open={accountMenuOpen}
            onClose={handleAccountMenuClose}
        >
            <MenuItem

                onClick={(ev) => {

                }} disableRipple>
                <ListItemIcon>
                    <PersonIcon />
                </ListItemIcon>
                <ListItemText
                    primary={auth.user ? (auth.user.email && auth.user.email !== "" ? auth.user.email : auth.user.username) : "No email/username"}
                    secondary={auth.user ? "ID: " + auth.user.id : ""} />
                {auth.user ? <IconButton aria-label="copy_team_id" onClick={() => {
                    if (auth.user === undefined || auth.user === null) {
                        return;
                    }
                    navigator.clipboard.writeText(auth.user.id);
                }}>
                    <ContentCopyIcon style={{ marginRight: "0px" }} />
                </IconButton> : undefined}

            </MenuItem>
            {myPrimaryTeam ? <MenuItem onClick={(ev) => {

            }}
                disableRipple>
                <ListItemIcon>
                    <GroupIcon />
                </ListItemIcon>
                <ListItemText
                    primary={myPrimaryTeam.name}
                    secondary={"ID: " + myPrimaryTeam.id} />
                <IconButton aria-label="copy_team_id" onClick={() => {
                    if (myPrimaryTeam === undefined || myPrimaryTeam.id === undefined) {
                        return;
                    }
                    navigator.clipboard.writeText(myPrimaryTeam.id);
                }}>
                    <ContentCopyIcon style={{ marginRight: "0px" }} />
                </IconButton>
            </MenuItem> : undefined}
            <Divider sx={{ my: 0.5 }} />
            <MenuItem onClick={() => {
                auth.logout().then(() => {
                    handleAccountMenuClose();
                });
            }} disableRipple>
                <LogoutIcon />
                Logout
            </MenuItem>
        </StyledMenu>
    </div >;
}