import { RecordModel } from "pocketbase";
import { ComponentType } from "../domain/ComponentType";

const ComponentTypeService = {
    transformRecord: (record: RecordModel) => {
        let res : ComponentType = {
            id: record.id,
            name: record["name"],
            icon: record["icon"],
        }
        return res;
    },
}

export default ComponentTypeService;