import * as React from 'react';
//import * as Ajv from 'ajv';
import Grid from '@mui/material/Grid';
import pb from '../services/PocketBase';
import GitHubIcon from '@mui/icons-material/GitHub';
import { Card, CardHeader, CardContent, Typography, List, Container, Skeleton, Paper, Stack, TextField, CardMedia, Button, CardActions, Chip, ListItem, IconButton, CardActionArea, Divider } from '@mui/material';
import { Component } from '../domain/Component';
import ComponentService from '../services/ComponentService';
import { useParams } from 'react-router-dom';
import ComponentListCard from '../components/ComponentListCard';
import ConnectionListCard from '../components/ConnectionListCard';
import ConnectionFormCard from '../components/ConnectionFormCard';
import { ConnectionTemplate } from '../domain/ConnectionTemplate';
import { Connection } from '../domain/Connection';
import ConnectionTemplateService from '../services/ConnectionTemplateService';
import SettingService from '../services/SettingService';
import ConnectionConfigFormCard from '../components/ConnectionConfigFormCard';
import { ConfigSchema } from '../domain/ConfigSchema';
import * as faker from 'json-schema-faker';
import APIService from '../services/APIService';
import DelegatedConnectionFormCard from '../components/DelegatedConnectionFormCard';

export default function DelegatedNewConnection() {
    const { delegateID, id, type } = useParams();
    const [newConnection, setNewConnection] = React.useState<Connection | undefined>(undefined);
    const [connectionTemplates, setConnectionTemplates] = React.useState<ConnectionTemplate[] | undefined>(undefined);
    const [validConfigs, setValidConfigs] = React.useState<boolean>(false);


    React.useEffect(() => {
        SettingService.getSetting("scheduler", delegateID).then((res) => {
            return APIService.getList("connection_templates", undefined, 100, {
                filter: "type = '" + type + "'",
                requestKey: "connections-templates-" + type,
                expand: "config_schema",
            }, delegateID)
                .then((res) => {
                    const connTempls = res.items.map((item) => {
                        return ConnectionTemplateService.transformRecord(item);
                    });

                    setConnectionTemplates([({
                        name: "Custom"
                    } as any) as ConnectionTemplate].concat(connTempls));
                });
        });
    }, [type]);

    React.useEffect(() => {
        setValidConfigs(false);
        setNewConnection({
            name: "",
            tag: "",
            type: type as string,
            componentID: id as string,
            config: {
                config: ``,
                configType: "custom",
                type: type as string,
            },
            configType: "",
            credentialsID: "",
        });
    }, [id, type]);

    if (connectionTemplates === undefined || newConnection === undefined) {
        return <Grid container height={"100%"}>
            <Grid item sm={12} md={3} lg={3}>
            </Grid>
        </Grid>
    }

    return <form onSubmit={(ev) => {
        ev.preventDefault();
    }} style={{ height: "100%" }}>
        <Grid container height={"100%"}>

            <Grid item sm={12} md={3} lg={3}>
                <DelegatedConnectionFormCard
                    delegateID={delegateID as string}
                    id={id as string}
                    type={type as string}
                    connectionTemplates={connectionTemplates}
                    newConnection={newConnection}
                    validConfigs={validConfigs}
                    onChange={(ev, ev2) => {
                        var n = {
                            ...newConnection,
                            [ev.target.name]: ev.target.value
                        };
                        if (ev.target.name === "_connectionTemplate") {
                            if (ev.target.value.configSchema) {
                                console.log(ev.target.value.configSchema);
                                faker.JSONSchemaFaker.option({ useExamplesValue: true, useDefaultValue: true });
                                const data = faker.JSONSchemaFaker.generate(ev.target.value.configSchema.configSchema);

                                n.config = {
                                    config: JSON.stringify(data, null, 2),
                                    configType: ev.target.value.configSchema.name,
                                    type: type as string,
                                };
                            }
                            n.template = ev.target.value;
                            setValidConfigs(false);
                        }
                        if (ev2) {
                            n = {
                                ...n,
                                [ev2.target.name]: ev2.target.value,
                            }
                            if (ev2.target.name === "_connectionTemplate") {
                                if (ev2.target.value.configSchemas) {
                                    n.config = {
                                        config: ``,
                                        configType: ev2.target.value.configSchema.name,
                                        type: type as string,
                                    };
                                }
                                n.template = ev2.target.value;
                                setValidConfigs(false);
                            }
                        }
                        setNewConnection(n);
                    }}></DelegatedConnectionFormCard>
            </Grid>
            <Grid item sm={12} md={9} lg={9} sx={{ display: "flex", flexDirection: "column", paddingLeft: "10px" }}>
                {newConnection && (newConnection as any)._connectionTemplate ? <ConnectionConfigFormCard id={id as string}
                    type={type as string}
                    connectionTemplate={(newConnection as any)._connectionTemplate as any}
                    newConnection={newConnection}
                    onValidationChange={(valid) => {
                        setValidConfigs(valid);
                    }}
                    onChange={(ev) => {
                        // if (ev.target.name === "config") {
                        //     if (newConnection.config === undefined) {
                        //         return;
                        //     }
                        //     newConnection.config.config = ev.target.value;
                        //     var n = {
                        //         ...newConnection
                        //     }
                        //     setNewConnection(n);
                        //     return;
                        // }
                        setNewConnection({ ...newConnection, [ev.target.name]: ev.target.value } as any);
                    }} /> : undefined}
            </Grid>
        </Grid>
    </form>
}