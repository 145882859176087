import { Button, Card, CardContent, CardHeader, CardMedia, Checkbox, Dialog, DialogActions, DialogContent, DialogTitle, Fab, FormControl, FormControlLabel, IconButton, InputLabel, List, ListItem, ListItemButton, ListItemIcon, ListItemText, MenuItem, Select, Stack, TextField, Typography } from "@mui/material";
import { useNavigate } from 'react-router-dom';
import Icon from '@mui/material/Icon';
import { Component } from "../domain/Component";
import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/Add';
import * as React from 'react';
import ComponentTypeService from "../services/ComponentTypeService";
import { ComponentType } from "../domain/ComponentType";
import pb from "../services/PocketBase";
import NotificationService from "../services/NotificationService";
import ComponentService from "../services/ComponentService";
import { CheckBox } from "@mui/icons-material";


interface ComponentListCardProps {
    id: string | undefined;
}

export default function ComponentListCard({ id }: ComponentListCardProps) {

    const nav = useNavigate();
    const [openAddComponentDialog, setOpenAddComponentDialog] = React.useState<boolean>(false);
    const [availableComponentTypes, setAvailableComponentTypes] = React.useState<ComponentType[] | undefined>(undefined);
    const [newComponent, setNewComponent] = React.useState<Component | undefined>(undefined);

    const [currentComponent, setCurrentComponent] = React.useState<Component | undefined>(undefined);
    const [componentChildren, setComponentChildren] = React.useState<Component[] | undefined>(undefined);

    const reloadComponents = () => {
        var filter = "parent.name = 'root' && parent.type.name = 'root'";
        if (id) {
            filter = "parent.id = '" + id + "'";
        }
        pb.collection("components").getList(undefined, 1000,
            {
                filter: filter,
                expand: "labels,type",
            }
        ).then((res) => {

            const comps = res.items.map((item) => {
                return ComponentService.transformRecord(item);
            });

            setComponentChildren(comps);

            if (id) {
                return pb.collection("components").getOne(id,
                    {
                        expand: "parent,labels,type",
                    }
                ).then((res) => {
                    setCurrentComponent(ComponentService.transformRecord(res));
                });
            } else {
                return pb.collection("components").getList(undefined, 1,
                    {
                        filter: "name = 'root' && type.name = 'root'",
                        expand: "labels,type",
                    }
                ).then((res) => {
                    if (res.items.length === 0) {
                        throw new Error("No root component found");
                    }

                    setCurrentComponent(ComponentService.transformRecord(res.items[0]));
                });
            }
        });
    };

    React.useEffect(() => {
        reloadComponents();
    }, [id]);

    const validComponent = (comp: Component | undefined) => {
        if (!comp) {
            return false;
        }
        return !comp?.name || !comp?.typeID;
    }

    React.useEffect(() => {
        pb.collection("component_types").getList(undefined, 100).then((res) => {

            const compTypes = res.items.filter((t) => {
                return t.name !== "root";
            }).map((item) => {
                return ComponentTypeService.transformRecord(item);
            });

            setAvailableComponentTypes(compTypes);
        });
    }, []);

    return <Card style={{ height: "100%" }} elevation={0}
        sx={{
            minWidth: 320,
            position: 'relative',
            boxShadow: '0 8px 24px 0 rgba(0,0,0,0.12)',
            overflow: 'visible',
            borderRadius: '1.5rem',
        }}>
        <CardHeader
            action={
                currentComponent ? <IconButton aria-label="add" onClick={() => {
                    setNewComponent({
                        name: "",
                        typeID: "",
                        delegated: false,
                        parentID: currentComponent.id as string,
                    });
                    setOpenAddComponentDialog(true);
                }}>
                    <AddIcon />
                </IconButton> : undefined
            }
            title={
                currentComponent && currentComponent.parentID ? <Stack direction="row" sx={{
                    alignItems: "center"
                }}>
                    <IconButton aria-label="upward navigation" onClick={() => {
                        nav("/config/components/" + currentComponent.parentID);
                    }}>
                        <Icon sx={{ fontSize: "1.5rem" }}>{"arrow_upward"}</Icon>
                    </IconButton>
                    <Typography>Components of {currentComponent.name}</Typography>
                </Stack> : <Typography>Components of {currentComponent?.name}</Typography>
            }
        />
        <CardContent sx={{ flexGrow: 1 }}>
            <List>
                {componentChildren ? componentChildren.map((child, i) => {
                    return <ListItem key={i} disablePadding secondaryAction={
                        <IconButton edge="end" aria-label="delete" onClick={() => {
                            if (window.confirm("Are you sure you want to delete this component?")) {
                                pb.collection("components").delete(child.id as string).then((res) => {
                                    NotificationService.notifySuccess("Component deleted");
                                    reloadComponents();
                                }).catch((err) => {
                                    NotificationService.notifyError("Failed to delete component: " + err.message);
                                });
                            }
                        }}>
                            <DeleteIcon />
                        </IconButton>
                    }>
                        <ListItemButton onClick={() => {
                            if (child.delegated === true) {
                                nav(`/config/delegated/${child.id}/components/${child.id}`);
                                return;
                            }
                            nav("/config/components/" + child.id);
                        }}>
                            <ListItemIcon>
                                <Icon sx={{ fontSize: "1.5rem" }}>{child.type?.icon}</Icon>
                            </ListItemIcon>
                            <ListItemText primary={child.name} secondary={child.description} />
                        </ListItemButton>
                    </ListItem>;
                }) : undefined}
            </List>
        </CardContent>
        <Dialog fullWidth onClose={() => {
            setOpenAddComponentDialog(false);
        }} open={openAddComponentDialog} PaperProps={{
            sx: {
                backgroundColor: "#404154"
            }
        }}>
            <form onSubmit={(ev) => {
                ev.preventDefault();
                pb.collection("components").create({
                    name: newComponent?.name,
                    type: newComponent?.typeID,
                    parent: newComponent?.parentID,
                    description: newComponent?.description,
                    delegated: newComponent?.delegated,
                }).then((res) => {
                    NotificationService.notifySuccess("Component created");
                    setOpenAddComponentDialog(false);
                    reloadComponents();
                }).catch((err) => {
                    NotificationService.notifyError("Failed to create component: " + err.message);
                });
            }}>
                <DialogTitle>Add new component</DialogTitle>
                <DialogContent>
                    <FormControl fullWidth sx={{ marginBottom: "40px" }}>
                        <TextField

                            required
                            id="comp-name"
                            value={newComponent?.name}
                            label="Name"
                            variant="standard"
                            onChange={(ev) => {
                                setNewComponent({ ...newComponent, name: ev.target.value as string } as any);
                            }}
                        />
                    </FormControl>
                    <FormControl fullWidth sx={{ marginBottom: "40px" }}>
                        <TextField
                            id="comp-description"
                            value={newComponent?.description}
                            label="Description"
                            variant="standard"
                            onChange={(ev) => {
                                setNewComponent({ ...newComponent, description: ev.target.value as string } as any);
                            }}
                        />
                    </FormControl>
                    <FormControl fullWidth>
                        <InputLabel id="demo-simple-select-label">Type</InputLabel>
                        <Select
                            required
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={newComponent?.typeID}
                            label="Type"
                            onChange={(ev) => {
                                setNewComponent({ ...newComponent, typeID: ev.target.value as string } as any);
                            }}
                            MenuProps={{
                                MenuListProps: {
                                    sx: {
                                        backgroundColor: "#404154"
                                    }
                                }
                            }}
                        >
                            {availableComponentTypes ? availableComponentTypes.map((compType, i) => {
                                return <MenuItem value={compType.id} key={i} sx={{ backgroundColor: "#404154" }}>{compType.name}</MenuItem>;
                            }) : <MenuItem value={"__LOADING__"} sx={{ backgroundColor: "#404154" }}>Loading...</MenuItem>}
                        </Select>
                    </FormControl>
                    <FormControlLabel control={<Checkbox checked={newComponent?.delegated}
                        onChange={(ev) => {
                            setNewComponent({ ...newComponent, delegated: ev.target.checked } as any);
                        }} />} label="Delegated?" />
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => {
                        setOpenAddComponentDialog(false);
                    }}>Cancel</Button>
                    <Button type="submit" disabled={validComponent(newComponent)}>Save</Button>
                </DialogActions>
            </form>
        </Dialog>
    </Card>
}