import * as React from 'react';
import Grid from '@mui/material/Grid';
import pb from '../services/PocketBase';
import GitHubIcon from '@mui/icons-material/GitHub';
import { Card, CardHeader, CardContent, Typography, List, Container, Skeleton, Paper, Stack, TextField, CardMedia, Button, CardActions, Chip, ListItem, IconButton, CardActionArea, Divider } from '@mui/material';
import { Component } from '../domain/Component';
import ComponentService from '../services/ComponentService';
import { useParams } from 'react-router-dom';
import ComponentListCard from '../components/ComponentListCard';
import ConnectionListCard from '../components/ConnectionListCard';

export default function Config() {
    const { id } = useParams();

    return <Grid container height={"100%"}>
        <Grid item sm={12} md={3} lg={3}>
            <ComponentListCard id={id}></ComponentListCard>
        </Grid>
        <Grid item sm={12} md={9} lg={9} sx={{display: "flex", flexDirection: "column"}}>
            <ConnectionListCard componentID={id} displayText='Data provider' connectionType='data-provider' marginTop='0px'></ConnectionListCard>
            <ConnectionListCard componentID={id} displayText='Processors' connectionType='processor'></ConnectionListCard>
            <ConnectionListCard componentID={id} displayText='Gateways' connectionType='gateway'></ConnectionListCard>
        </Grid>
    </Grid>
}
