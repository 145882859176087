import { RecordListOptions, RecordModel } from "pocketbase";
import { Team } from "../domain/Team";
import pb from "./PocketBase";
import useSWR from "swr";

const listFetcher = ([collection, page, perPage, options] : [collection: string, page: number| undefined,perPage: number| undefined, options: RecordListOptions | undefined]) => {
    return pb.collection(collection).getList(page, perPage, options);
}

const TeamService = {
    transformRecord: (record: RecordModel) => {
        let res : Team = {
            id: record.id,
            name: record["name"],
            external: record["external"]
        }
        return res;
    },
    myTeams: (userID: string | undefined) => {
        // if (!userID) {
        //     return {
        //         user: undefined,
        //         isLoading: true,
        //         isError: undefined
        //     }
        // }
        // const { data, error, isLoading } = useSWR([`teams`, undefined,undefined, {
        //     filter: 'members.id ?= "' + userID + '"'
        // }], listFetcher);
 
        // return {
        //     user: data,
        //     isLoading,
        //     isError: error
        // }
        return pb.collection("teams").getList(undefined, undefined, {
            filter: 'members.id ?= "' + userID + '"'
        })
        .then((res) => {
            return res.items.map((rec) => {
                return TeamService.transformRecord(rec);
            })
        });
    },
    deleteTeam: (id: string) => {
        return pb.collection("teams").delete(id);
    },
    updateAssignedMembers: (id: string, members?: string[]) => {
        return pb.collection("teams").update(id, {
            members: members
        });
    },
    createTeam: (src: Team) => {
        return pb.collection("teams").create<Team>(src);
    },
}

export default TeamService;