import * as React from 'react';
import Grid from '@mui/material/Grid';
import pb from '../services/PocketBase';
import GitHubIcon from '@mui/icons-material/GitHub';
import { Card, CardHeader, CardContent, Typography, List, Container, Skeleton, Paper, Stack, TextField, CardMedia, Button, CardActions, Chip, ListItem, IconButton, CardActionArea, Divider } from '@mui/material';
import { Component } from '../domain/Component';
import ComponentService from '../services/ComponentService';
import { Pages } from '../nav/ListItems';
import TileCard from '../components/TileCard';
import { Tile } from '../domain/Tile';
import TileService from '../services/TileService';

export default function Home() {
    const [tiles, setTiles] = React.useState<Tile[] | undefined>(undefined);

    React.useEffect(() => {
        pb.collection("tiles").getList(undefined, 100)
        .then((res) => {
            const tiles = res.items.map((item) => {
                return TileService.transformRecord(item);
            });
            setTiles(tiles);
        });
    }, []);
    return <Grid container spacing={3} sx={{ marginTop: "0px" }} alignItems="stretch">
        {Pages.filter((k) => {
            return k.routes[0] !== "home" && !k.internal;
        }).map((k,i) => {
            if (!k.icon) {
                k.icon = "question_mark";
            }

            return <Grid key={i} item xs={12} md={6} lg={3}>
                <TileCard tile={{
                    name: k.text,
                    icon: k.icon,
                    route: k.routes[0],
                    description: k.description
                }} />
            </Grid>
        })}
        {tiles ? tiles.map((k,i) => {

            return <Grid key={i} item xs={12} md={6} lg={3}>
                <TileCard tile={{
                    name: k.name,
                    icon: k.icon,
                    url: k.url,
                    description: k.description
                }} />
            </Grid>
        }) : undefined}
    </Grid>;
}
