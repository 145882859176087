import { Component } from "../domain/Component";
import LabelService from "./LabelService";
import ComponentTypeService from "./ComponentTypeService";
import { RecordModel } from "pocketbase";

const ComponentService = {
    transformRecord: (record: RecordModel) => {
        let res: Component = {
            id: record.id,
            name: record["name"],
            description: record["description"],
            typeID: record["type"],
            delegated: record["delegated"],
            type: record.expand && record.expand["type"] ? ComponentTypeService.transformRecord(record.expand["type"] as RecordModel) : undefined,
            labels: record.expand && record.expand["labels"] ? (record.expand["labels"] as RecordModel[]).map(l => LabelService.transformRecord(l)) : undefined,
            parent: record.expand && record.expand["parent"] ? ComponentService.transformRecord(record.expand["parent"] as RecordModel) : undefined,
            parentID: record["parent"],
        }
        return res;
    },
}

export default ComponentService;