export interface DataSet {
    name: string,
    time: Date,
    data: {[name: string]: {
        value: number | string | boolean,
    }}
}

export function dataSetsFromJSON(msg: any): DataSet[] {
    return msg.dataSets.map((dataSet: any) => {
        if (dataSet.time) {
            dataSet.time = new Date(dataSet.time);
        } else {
            dataSet.time = new Date(msg.time);
        }
        return dataSet as DataSet;
    });
}