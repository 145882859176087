import * as React from 'react';
import Grid from '@mui/material/Grid';
import pb from '../services/PocketBase';
import GitHubIcon from '@mui/icons-material/GitHub';
import { Card, CardHeader, CardContent, Typography, List, Container, Skeleton, Paper, Stack, TextField, CardMedia, Button, CardActions, Chip, ListItem, IconButton, CardActionArea, Divider } from '@mui/material';
import { Component } from '../domain/Component';
import ComponentService from '../services/ComponentService';
import { useParams } from 'react-router-dom';
import ComponentListCard from '../components/ComponentListCard';
import ConnectionListCard from '../components/ConnectionListCard';
import DelegatedComponentListCard from '../components/DelegatedComponentListCard';
import DelegatedConnectionListCard from '../components/DelegatedConnectionListCard';

export default function DelegatedConfig() {
    const { delegateID, id } = useParams();

    return <Grid container height={"100%"}>
        <Grid item sm={12} md={3} lg={3}>
            {delegateID ? <DelegatedComponentListCard delegateID={delegateID} id={id}></DelegatedComponentListCard> : undefined}
        </Grid>
        <Grid item sm={12} md={9} lg={9} sx={{display: "flex", flexDirection: "column"}}>
            <DelegatedConnectionListCard delegateID={delegateID as string} componentID={id as string} displayText='Data provider' connectionType='data-provider' marginTop='0px'></DelegatedConnectionListCard>
            <DelegatedConnectionListCard delegateID={delegateID as string} componentID={id as string} displayText='Processors' connectionType='processor'></DelegatedConnectionListCard>
            <DelegatedConnectionListCard delegateID={delegateID as string} componentID={id as string} displayText='Gateways' connectionType='gateway'></DelegatedConnectionListCard>
        </Grid>
    </Grid>
}
