import { RecordModel } from "pocketbase";
import { Credentials } from "../domain/Credentials";

const CredentialService = {
    transformRecord: (record: RecordModel) => {
        let res: Credentials = {
            id: record.id,
            key: record["key"],
            secret: record["secret"],
        }
        return res;
    },
}

export default CredentialService;