import { RecordModel } from "pocketbase";
import { ConnectionTemplate } from "../domain/ConnectionTemplate";
import ConfigSchemaService from "./ConfigSchemaService";

const ConnectionTemplateService = {
    transformRecord: (record: RecordModel) => {
        let res: ConnectionTemplate = {
            id: record.id,
            name: record["name"],
            version: record["version"],
            type: record["type"],
            scopes: record["scopes"],
            configSchemaID: record["config_schema"],
            configSchema: record.expand && record.expand["config_schema"] ? ConfigSchemaService.transformRecord(record.expand["config_schema"] as RecordModel) : undefined,
        }
        return res;
    },
}

export default ConnectionTemplateService;