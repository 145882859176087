import { Button, Card, CardContent, CardHeader, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, List, Typography } from "@mui/material";
import AddIcon from '@mui/icons-material/Add';
import * as React from 'react';
import pb from "../services/PocketBase";
import { Connection } from "../domain/Connection";
import ConnectionService from "../services/ConnectionService";
import { Link, useNavigate } from "react-router-dom";
import LiveDataDialog from "./LiveDataDialog";
import ConnectionListItem from "./ConnectionListItem";
import LogViewDialog from "./LogViewDialog";
import RequestReplyDialog from "./RequestReplyDialog";
import { QRCodeSVG } from "qrcode.react";
import SettingService from "../services/SettingService";
import APIService from "../services/APIService";


interface DelegatedConnectionListCardProps {
    delegateID: string;
    componentID: string;
    displayText: string;
    connectionType: string;
    marginTop?: string;
}

export default function DelegatedConnectionListCard({ delegateID, componentID, displayText, connectionType, marginTop }: DelegatedConnectionListCardProps) {

    const [connections, setConnections] = React.useState<Connection[] | undefined>(undefined);
    const [openViewCredentialsDialog, setOpenViewCredentialsDialog] = React.useState<Connection | undefined>(undefined);
    const [openViewLiveDataDialog, setOpenViewLiveDataDialog] = React.useState<Connection | undefined>(undefined);
    const [openViewLogsDialog, setOpenViewLogsDialog] = React.useState<Connection | undefined>(undefined);
    const [openRequestReplyDialog, setOpenRequestReplyDialog] = React.useState<Connection | undefined>(undefined);
    const [natsURL, setNatsURL] = React.useState<string | undefined>(undefined);
    const navigate = useNavigate();

    const reloadConnections = (delegateID: string, componentID: string | undefined) => {
        var filter = "component.id = '" + componentID + "' && type = '" + connectionType + "'";
        return APIService.getList("connections", undefined, 100, {
            filter: filter,
            expand: "component,labels,credentials",
            requestKey: "connections-" + componentID + "-" + connectionType,
        }, delegateID)
            .then((res) => {

                const conns = res.items.map((item) => {
                    return ConnectionService.transformRecord(item);
                });

                setConnections(conns);

                return conns;
            });
    };

    React.useEffect(() => {
        SettingService.getSettings(delegateID).then((res) => {
            if (res === undefined) {
                return Promise.reject("No settings found");
            }
            var host = res["nats_websocket_host"].value;

            if (host === undefined || host === "") {
                host = window.location.hostname;
            }

            setNatsURL(res["nats_websocket_protocol"].value + "://" + host + ":" + res["nats_websocket_port"].value);
        })
    }, [delegateID]);

    React.useEffect(() => {
        reloadConnections(delegateID, componentID);
    }, [delegateID, componentID, connectionType]);

    return <Card elevation={0}
        sx={{
            minWidth: 320,
            position: 'relative',
            boxShadow: '0 8px 24px 0 rgba(0,0,0,0.12)',
            borderRadius: '1.5rem',
            flexGrow: 1,
            margin: "10px",
            marginBottom: "0px",
            marginTop: marginTop
        }}>
        <CardHeader
            title={displayText}
            action={
                componentID ? <Link to={"connections/" + connectionType + "/new"}>
                    <IconButton aria-label="add">
                        <AddIcon />
                    </IconButton>
                </Link> : undefined
            }
        />
        <CardContent sx={{ flexGrow: 1 }}>
            <List>
                {connections ? connections.map((conn, i) => {
                    return <ConnectionListItem connection={conn} onDelete={() => {
                        reloadConnections(delegateID, componentID);
                    }} setOpenViewCredentialsDialog={setOpenViewCredentialsDialog}
                        setOpenViewLiveDataDialog={setOpenViewLiveDataDialog}
                        setOpenViewLogsDialog={setOpenViewLogsDialog}
                        setOpenRequestReplyDialog={setOpenRequestReplyDialog}
                        key={i} />;
                }) : undefined}
            </List>
        </CardContent>
        <Dialog fullWidth onClose={() => {
            setOpenViewCredentialsDialog(undefined);
        }} open={openViewCredentialsDialog !== undefined} PaperProps={{
            sx: {
                backgroundColor: "#404154"
            }
        }}>
            <DialogTitle>Credentials of {openViewCredentialsDialog?.name} connection</DialogTitle>
            <DialogContent>
                <Typography variant="h6" sx={{ marginBottom: "20px" }}>Key</Typography>
                <Typography variant="body1" sx={{ marginBottom: "20px", wordWrap: "break-word" }}>{openViewCredentialsDialog?.credentials?.key}</Typography>
                <Typography variant="h6" sx={{ marginBottom: "20px" }}>Secret</Typography>
                <Typography variant="body1" sx={{ marginBottom: "20px", wordWrap: "break-word" }}>{openViewCredentialsDialog?.credentials?.secret}</Typography>
                {natsURL ? <QRCodeSVG value={JSON.stringify({
                    ...openViewCredentialsDialog?.credentials,
                    websocket: natsURL,
                    connectionID: openViewCredentialsDialog?.id,
                })} width={256} height={256} /> : undefined}
            </DialogContent>
            <DialogActions>
                <Button onClick={() => {
                    setOpenViewCredentialsDialog(undefined);
                }}>Close</Button>
            </DialogActions>
        </Dialog>
        <LiveDataDialog id={openViewLiveDataDialog?.id as string} name={openViewLiveDataDialog?.name as string} open={openViewLiveDataDialog !== undefined} onClose={() => {
            setOpenViewLiveDataDialog(undefined);
        }} />
        <LogViewDialog id={openViewLogsDialog?.id as string} name={openViewLogsDialog?.name as string} open={openViewLogsDialog !== undefined} onClose={() => {
            setOpenViewLogsDialog(undefined);
        }} />
        <RequestReplyDialog connection={openRequestReplyDialog as Connection} open={openRequestReplyDialog !== undefined} onClose={() => {
            setOpenRequestReplyDialog(undefined);
        }} />
    </Card>
}
