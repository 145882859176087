import { RecordModel } from "pocketbase";
import { Setting, Settings } from "../domain/Setting";
import pb from "./PocketBase";
import APIService from "./APIService";

let settingsCache: {
    [delegateID: string]: Promise<Settings> | undefined
} = {};

const getDelegateID = (delegateID: string | undefined) => {
    if (!delegateID) {
        return "_global_";
    }
    return delegateID;
}

const loadSettings = (delegateID: string | undefined) => {
    settingsCache[getDelegateID(delegateID)] = APIService.getList("settings", undefined, 1000,{}, delegateID)
    .then((res) => {
        var result: Settings = {};

        res.items.forEach((item) => {
            result[item["name"]] = SettingService.transformRecord(item);
        });

        return result;
    });
    return settingsCache[getDelegateID(delegateID)] as Promise<Settings>;
}

const SettingService = {
    transformRecord: (record: RecordModel) => {
        let res: Setting = {
            id: record.id,
            name: record["name"],
            value: record["value"],
        }
        return res;
    },
    getSetting: (name: string, delegateID: string | undefined): Promise<Setting |undefined> => {
        if (settingsCache[getDelegateID(delegateID)] === undefined) {
            return loadSettings(delegateID).then((res) => {
                return res[name];
            });
        }
        return (settingsCache[getDelegateID(delegateID)] as Promise<Settings>).then((res) => {
            return res[name];
        });
    },
    getSettings: (delegateID: string | undefined): Promise<Settings |undefined> => {
        if (settingsCache[getDelegateID(delegateID)] === undefined) {
            return loadSettings(delegateID);
        }
        return (settingsCache[getDelegateID(delegateID)] as Promise<Settings>);
    },
}

export default SettingService;