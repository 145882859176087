import { Button, Card, CardContent, CardHeader, CardMedia, Checkbox, Dialog, DialogActions, DialogContent, DialogTitle, Fab, FormControl, FormControlLabel, IconButton, InputLabel, List, ListItem, ListItemButton, ListItemIcon, ListItemText, MenuItem, Select, Stack, TextField, Typography } from "@mui/material";
import { useNavigate } from 'react-router-dom';
import Icon from '@mui/material/Icon';
import { Component } from "../domain/Component";
import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/Add';
import * as React from 'react';
import ComponentTypeService from "../services/ComponentTypeService";
import { ComponentType } from "../domain/ComponentType";
import pb from "../services/PocketBase";
import NotificationService from "../services/NotificationService";
import ComponentService from "../services/ComponentService";
import { CheckBox } from "@mui/icons-material";
import APIService from "../services/APIService";


interface DelegatedComponentListCardProps {
    delegateID: string;
    id: string | undefined;
}

export default function DelegatedComponentListCard({ delegateID, id }: DelegatedComponentListCardProps) {

    const nav = useNavigate();
    const [openAddComponentDialog, setOpenAddComponentDialog] = React.useState<boolean>(false);
    const [availableComponentTypes, setAvailableComponentTypes] = React.useState<ComponentType[] | undefined>(undefined);
    const [newComponent, setNewComponent] = React.useState<Component | undefined>(undefined);

    const [currentComponent, setCurrentComponent] = React.useState<Component | undefined>(undefined);
    const [componentChildren, setComponentChildren] = React.useState<Component[] | undefined>(undefined);

    const reloadComponents = () => {
        if (!id || id === delegateID) {
            // root level of a delegate => fetch every component without a parent
            return APIService.getList("components", undefined, 1000, {
                filter: "parent = '" + delegateID + "'",
                expand: "labels,type",
            }, delegateID)
                .then((res) => {
                    const comps = res.items.map((item) => {
                        return ComponentService.transformRecord(item);
                    });

                    setComponentChildren(comps);
                    return APIService.getOne("components", delegateID,
                        {
                            expand: "parent,labels,type",
                        }
                    );
                })
                .then((res) => {
                    setCurrentComponent(ComponentService.transformRecord(res));
                });
        }
        if (id) {
            // deeper level of a delegate => fetch every component without the id as a parent
            return APIService.getList("components", undefined, 1000, {
                filter: "parent.id = '" + id + "'",
                expand: "labels,type",
            }, delegateID)
                .then((res) => {
                    const comps = res.items.map((item) => {
                        return ComponentService.transformRecord(item);
                    });

                    setComponentChildren(comps);
                    return APIService.getOne("components", id,
                        {
                            expand: "parent,labels,type",
                        }, delegateID
                    );
                })
                .then((res) => {
                    setCurrentComponent(ComponentService.transformRecord(res));
                });
        }
    };

    React.useEffect(() => {
        reloadComponents();
    }, [delegateID, id]);

    const validComponent = (comp: Component | undefined) => {
        if (!comp) {
            return false;
        }
        return !comp?.name || !comp?.typeID;
    }

    React.useEffect(() => {
        APIService.getList("component_types", undefined, 100).then((res) => {

            const compTypes = res.items.filter((t) => {
                return t.name !== "root";
            }).map((item) => {
                return ComponentTypeService.transformRecord(item);
            });

            setAvailableComponentTypes(compTypes);
        });
    }, []);

    return <Card style={{ height: "100%" }} elevation={0}
        sx={{
            minWidth: 320,
            position: 'relative',
            boxShadow: '0 8px 24px 0 rgba(0,0,0,0.12)',
            overflow: 'visible',
            borderRadius: '1.5rem',
        }}>
        <CardHeader
            action={
                currentComponent ? <IconButton aria-label="add" onClick={() => {
                    setNewComponent({
                        name: "",
                        typeID: "",
                        delegated: false,
                        parentID: delegateID === currentComponent.id ? undefined : currentComponent.id as string,
                    });
                    setOpenAddComponentDialog(true);
                }}>
                    <AddIcon />
                </IconButton> : undefined
            }
            title={
                <Stack direction="row" sx={{
                    alignItems: "center"
                }}>
                    {currentComponent ? <IconButton aria-label="upward navigation" onClick={() => {
                        if (currentComponent.delegated === true) {
                            // we are delegated => next layer up is no longer delegated
                            nav("/config/components/" + currentComponent.parentID);
                            return;
                        }
                        if (currentComponent.parentID) {
                            nav(`/config/delegated/${delegateID}/components/${currentComponent.parentID}`);
                            return;
                        }
                        nav(`/config/delegated/${delegateID}/components/${delegateID}`);
                    }}>
                        <Icon sx={{ fontSize: "1.5rem" }}>{"arrow_upward"}</Icon>
                    </IconButton> : undefined}
                    <Typography>Components of {currentComponent?.name}</Typography>
                </Stack>
            }
        />
        <CardContent sx={{ flexGrow: 1 }}>
            <List>
                {componentChildren ? componentChildren.map((child, i) => {
                    return <ListItem key={i} disablePadding secondaryAction={
                        <IconButton edge="end" aria-label="delete" onClick={() => {
                            if (window.confirm("Are you sure you want to delete this component?")) {
                                APIService.delete("components", child.id as string, undefined, delegateID).then((res) => {
                                    NotificationService.notifySuccess("Component deleted");
                                    reloadComponents();
                                }).catch((err) => {
                                    NotificationService.notifyError("Failed to delete component: " + err.message);
                                });
                            }
                        }}>
                            <DeleteIcon />
                        </IconButton>
                    }>
                        <ListItemButton onClick={() => {
                            nav(`/config/delegated/${delegateID}/components/${child.id}`);
                        }}>
                            <ListItemIcon>
                                <Icon sx={{ fontSize: "1.5rem" }}>{child.type?.icon}</Icon>
                            </ListItemIcon>
                            <ListItemText primary={child.name} secondary={child.description} />
                        </ListItemButton>
                    </ListItem>;
                }) : undefined}
            </List>
        </CardContent>
        <Dialog fullWidth onClose={() => {
            setOpenAddComponentDialog(false);
        }} open={openAddComponentDialog} PaperProps={{
            sx: {
                backgroundColor: "#404154"
            }
        }}>
            <form onSubmit={(ev) => {
                ev.preventDefault();
                return APIService.create("components", {
                    name: newComponent?.name,
                    type: newComponent?.typeID,
                    parent: !id || id === delegateID ? delegateID : newComponent?.parentID,
                    description: newComponent?.description,
                }, undefined, delegateID).then((res) => {
                    NotificationService.notifySuccess("Component created");
                    setOpenAddComponentDialog(false);
                    reloadComponents();
                }).catch((err) => {
                    NotificationService.notifyError("Failed to create component: " + err.message);
                });
            }}>
                <DialogTitle>Add new component</DialogTitle>
                <DialogContent>
                    <FormControl fullWidth sx={{ marginBottom: "40px" }}>
                        <TextField
                            autoFocus
                            required
                            id="comp-name"
                            value={newComponent?.name}
                            label="Name"
                            variant="standard"
                            onChange={(ev) => {
                                setNewComponent({ ...newComponent, name: ev.target.value as string } as any);
                            }}
                        />
                    </FormControl>
                    <FormControl fullWidth sx={{ marginBottom: "40px" }}>
                        <TextField
                            id="comp-description"
                            value={newComponent?.description}
                            label="Description"
                            variant="standard"
                            onChange={(ev) => {
                                setNewComponent({ ...newComponent, description: ev.target.value as string } as any);
                            }}
                        />
                    </FormControl>
                    <FormControl fullWidth>
                        <InputLabel id="demo-simple-select-label">Type</InputLabel>
                        <Select
                            required
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={newComponent?.typeID}
                            label="Type"
                            onChange={(ev) => {
                                setNewComponent({ ...newComponent, typeID: ev.target.value as string } as any);
                            }}
                            MenuProps={{
                                MenuListProps: {
                                    sx: {
                                        backgroundColor: "#404154"
                                    }
                                }
                            }}
                        >
                            {availableComponentTypes ? availableComponentTypes.map((compType, i) => {
                                return <MenuItem value={compType.id} key={i}>{compType.name}</MenuItem>;
                            }) : <MenuItem value={"__LOADING__"}>Loading...</MenuItem>}
                        </Select>
                    </FormControl>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => {
                        setOpenAddComponentDialog(false);
                    }}>Cancel</Button>
                    <Button type="submit" disabled={validComponent(newComponent)}>Save</Button>
                </DialogActions>
            </form>
        </Dialog>
    </Card>
}