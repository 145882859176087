import { Autocomplete, Avatar, Card, CardContent, CardHeader, FormControl, Icon, IconButton, List, ListItem, ListItemAvatar, ListItemText, ListSubheader, TextField, Typography, debounce } from "@mui/material";
import SaveIcon from '@mui/icons-material/Save';
import { Connection } from "../domain/Connection";
import { ConnectionTemplate } from "../domain/ConnectionTemplate";
import React, { useMemo } from "react";
import { useNavigate } from 'react-router-dom';
import pb from "../services/PocketBase";
import { Worker } from "../domain/Worker";
import WorkerTemplateService from "../services/WorkerTemplateService";
import { WorkerTemplate } from "../domain/WorkerTemplate";
import APIService from "../services/APIService";


interface DelegatedConnectionFormCardProps {
    delegateID: string;
    id: string;
    type: string;
    newConnection: Connection;
    onChange: (ev: any, ev2?: any) => void;
    connectionTemplates: ConnectionTemplate[];
    validConfigs: boolean;
}

export default function DelegatedConnectionFormCard({ delegateID, id, type, newConnection, onChange, connectionTemplates, validConfigs }: DelegatedConnectionFormCardProps) {

    const nav = useNavigate();

    const [availableWorkers, setAvailableWorkers] = React.useState<Worker[] | undefined>([]);
    const [availableWorkerTemplates, setAvailableWorkerTemplates] = React.useState<WorkerTemplate[] | undefined>([]);

    const [valid, setValid] = React.useState<boolean>(false);
    React.useEffect(() => {
        if (newConnection.name && newConnection.configType) {
            setValid(true);
        } else {
            setValid(false);
        }
    }, [newConnection]);

    const getAvailableWorkers = useMemo(() => {
        return debounce((newConnection) => {
            setAvailableWorkerTemplates(undefined);
            if (!newConnection) {
                return;
            }

            if (!newConnection.configType) {
                return;
            }

            if (newConnection.configType === "") {
                return;
            }

            var q: {
                type: string;
                configType: string;
                tag?: string;
            } = {
                type: type,
                configType: newConnection.configType,
            };

            if (newConnection.tag && newConnection.tag !== "") {
                q.tag = newConnection.tag;
            }

            APIService.send("/api/workers", {
                method: "GET",
                query: q
            }, delegateID)
                .then((res) => {
                    if (Array.isArray(res)) {
                        setAvailableWorkers(res);
                    } else {
                        setAvailableWorkers([]);
                    }
                    return APIService.getList("worker_templates", undefined, undefined, {
                        filter: `name = '${newConnection.configType}' && type = '${type}'`
                    }, delegateID);
                })
                .then((res) => {
                    setAvailableWorkerTemplates(res.items.map((wt) => {
                        return WorkerTemplateService.transformRecord(wt);
                    }));
                });
        }, 400);
    }, []);

    React.useEffect(() => {
        getAvailableWorkers(newConnection);
    }, [type, newConnection.configType, newConnection.tag]);


    return <Card style={{ height: "100%" }} elevation={0}
        sx={{
            minWidth: 320,
            position: 'relative',
            boxShadow: '0 8px 24px 0 rgba(0,0,0,0.12)',
            overflow: 'visible',
            borderRadius: '1.5rem',
        }}>
        <CardHeader
            action={
                <IconButton
                    aria-label="save"
                    type="submit"
                    disabled={!valid || !validConfigs}
                    onClick={() => {
                        console.log(newConnection);
                        var n = {
                            ...newConnection,
                        } as any;
                        n.component = n.componentID;
                        n.config_type = n.configType;
                        pb.collection("configs").create({
                            config: n.config,
                            config_type: n.configType,
                            type: n.type,
                        }).then((res) => {
                            n.config = res.id;
                            return pb.collection("connections").create(n).then((res) => {
                                console.log(res);
                            });
                        }).then((res) => {
                            nav("/config/" + n.componentID);
                        });
                    }}>
                    <SaveIcon />
                </IconButton>
            }
            title={
                <Typography>New {type} connection</Typography>
            }
        />
        <CardContent sx={{ flexGrow: 1 }}>
            <FormControl fullWidth sx={{ marginBottom: "40px" }}>
                <TextField
                    required
                    id="conn-name"
                    value={newConnection?.name}
                    label="Name"
                    name="name"
                    variant="standard"
                    onChange={onChange}
                />
            </FormControl>
            <FormControl fullWidth sx={{ marginBottom: "40px" }}>
                <TextField
                    id="conn-description"
                    value={newConnection?.description}
                    label="Description"
                    name="description"
                    variant="standard"
                    onChange={onChange}
                />
            </FormControl>
            <FormControl fullWidth sx={{ marginBottom: "40px" }}>
                {connectionTemplates ? <Autocomplete
                    disablePortal
                    id="combo-box-demo"
                    onChange={(ev, value) => {
                        onChange({
                            target: {
                                name: "_connectionTemplate",
                                value: value,
                            }
                        }, {
                            target: {
                                name: "configType",
                                value: value?.id ? value?.name : "",
                            }
                        });
                    }}
                    getOptionLabel={(option) => option.name}
                    options={connectionTemplates}
                    sx={{ width: 300 }}
                    renderInput={(params: any) => <TextField {...params} label="Connection type" />}
                /> : undefined}
            </FormControl>
            <FormControl fullWidth sx={{ marginBottom: "40px" }}>
                <TextField
                    id="conn-tag"
                    value={newConnection?.tag}
                    label="Tag"
                    name="tag"
                    variant="standard"
                    onChange={onChange}
                />
            </FormControl>
            <List sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }} subheader={<ListSubheader>Available worker</ListSubheader>}>
                {availableWorkers ? availableWorkers.map((w, i) => {
                    return <ListItem key={i}>
                        <ListItemAvatar>
                            <Avatar>
                                <Icon sx={{ fontSize: "1.5rem" }}>{
                                    w.status.status === "running" ? "play_arrow" : "" +
                                        w.status.status === "stopped" ? "stop" : "" +
                                            w.status.status === "error" ? "error" : "" +
                                                w.status.status === "pending" ? "hourglass_top" : "" +
                                                    w.status.status === "unknown" ? "question_mark" : ""
                                }</Icon>
                            </Avatar>
                        </ListItemAvatar>
                        <ListItemText primary={w.instanceID} secondary={(w.tag && w.tag !== "" ? (w.tag + " - ") : "No tag - ") + (w.status.message && w.status.message !== "" ? w.status.message : "No status message")} />
                    </ListItem>
                }) : undefined}
                {availableWorkers && availableWorkers.length === 0 ? <ListItem>None</ListItem> : undefined}
                {availableWorkers && availableWorkers.length === 0 && availableWorkerTemplates && availableWorkerTemplates.length > 0 ? <ListItem>But will be automatically provisioned</ListItem> : undefined}
            </List>
        </CardContent>
    </Card>
}