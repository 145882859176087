import { Icon, IconButton, ListItem, ListItemButton, ListItemIcon, ListItemText, Stack, Tooltip, Typography } from "@mui/material";
import DeleteIcon from '@mui/icons-material/Delete';
import KeyIcon from '@mui/icons-material/Key';
import * as React from 'react';
import pb from "../services/PocketBase";
import NotificationService from "../services/NotificationService";
import { Connection } from "../domain/Connection";
import VisibilityIcon from '@mui/icons-material/Visibility';
import SubjectIcon from '@mui/icons-material/Subject';
import CompareArrowsIcon from '@mui/icons-material/CompareArrows';
import NATSService from "../services/NATSService";
import { ConnectionInfo } from "../domain/ConnectionInfo";
import { KvEntry, QueuedIterator } from "nats.ws";

interface ConnectionListItemProps {
    connection: Connection;
    setOpenViewCredentialsDialog: (conn: Connection) => void;
    setOpenViewLiveDataDialog: (conn: Connection) => void;
    setOpenViewLogsDialog: (conn: Connection) => void;
    setOpenRequestReplyDialog: (conn: Connection) => void;
    onDelete: (conn: Connection) => void;
}

export default function ConnectionListItem({ connection,
    setOpenViewCredentialsDialog,
    setOpenViewLiveDataDialog,
    setOpenViewLogsDialog,
    setOpenRequestReplyDialog,
    onDelete }: ConnectionListItemProps) {

    const [connectionInfos, setConnectionInfos] = React.useState<{ [instanceID: string]: ConnectionInfo }>({});
    const [kvWatcher, setKVWatcher] = React.useState<QueuedIterator<KvEntry> | undefined>(undefined);

    const updateInfo = () => {
        if (!connection) {
            return;
        }
        NATSService.getConnectionInfoKV()
            .then((kv) => {
                // TODO: convert to watch and do something on all initial values
                return kv.watch({
                    key: `connection_infos.${connection.id}.${connection.type}.${connection.configType}.>`,
                    initializedFn: () => {

                    }
                });
                //return kv.get(`connection_infos.${connection.id}.${connection.type}.${connection.configType}`);
            })
            .then(async (kvIterator) => {
                if (kvWatcher) {
                    kvWatcher.stop();
                }
                setKVWatcher(kvIterator);
                for await (const entry of kvIterator) {
                    if (entry) {
                        const ci = JSON.parse(new TextDecoder().decode(entry.value));
                        ci.time = new Date(ci.time);
                        switch (entry.operation) {
                            case "PUT":
                                setConnectionInfos((prev) => {
                                    return {
                                        ...prev,
                                        [ci.instanceID]: ci
                                    };
                                });
                                break;
                            case "DEL":
                                setConnectionInfos((prev) => {
                                    const newPrev = { ...prev };
                                    delete newPrev[ci.instanceID];
                                    return newPrev;
                                });
                                break;
                            case "PURGE":
                                setConnectionInfos((prev) => {
                                    const newPrev = { ...prev };
                                    delete newPrev[ci.instanceID];
                                    return newPrev;
                                });
                                break;
                            default:
                                break;
                        }
                    }
                }
            });
    };

    React.useEffect(() => {
        updateInfo();
        return () => {
            if (kvWatcher) {
                kvWatcher.stop();
            }
        }
    }, [connection]);

    const getTooltip = () => {
        if (!connectionInfos) {
            return "Unknown";
        }

        var result = "";

        Object.keys(connectionInfos).forEach((instanceID) => {
            const ci = connectionInfos[instanceID];
            if (ci.active === false) {
                result += `${ci.time.toLocaleString()} - Connection ${ci.instanceID} is not active\n`;
                return;
            }
            if (new Date().getTime() - ci.time.getTime() >= 90000) {
                result += `${ci.time.toLocaleString()} - Connection ${ci.instanceID} has not reported in 90 seconds\n`;
                return;
            }
            if (ci.connectionStates.ok !== true) {
                result += `${ci.time.toLocaleString()} - Connection ${ci.instanceID} is not ok\n`;
                return;
            }

            result += `${ci.time.toLocaleString()} - Connection ${ci.instanceID} is ok\n`;
        });

        return result;
    }

    const getIcon = () => {
        if (!connectionInfos) {
            return "question_mark";
        }

        if (Object.keys(connectionInfos).length === 0) {
            return "question_mark";
        }

        var alive = false;
        var ok = false;

        Object.keys(connectionInfos).forEach((instanceID) => {
            const ci = connectionInfos[instanceID];
            if (new Date().getTime() - ci.time.getTime() >= 90000) {
                return;
            }
            if (ci.active === true) {
                alive = true;
            }
            if (ci.connectionStates.ok === true) {
                ok = true;
            }
        });

        if (alive === false) {
            return "stop";
        }
        if (ok === false) {
            return "cloud_off";
        }
        return "play_arrow";
    }

    return <ListItem disablePadding secondaryAction={
        <Stack direction="row" spacing={2}>
            <IconButton edge="end" aria-label="secrets" onClick={() => {
                setOpenViewCredentialsDialog(connection);
            }}>
                <KeyIcon />
            </IconButton>
            <IconButton edge="end" aria-label="live-data" onClick={() => {
                setOpenViewLiveDataDialog(connection);
            }}>
                <VisibilityIcon />
            </IconButton>
            <IconButton edge="end" aria-label="logs" onClick={() => {
                setOpenViewLogsDialog(connection);
            }}>
                <SubjectIcon />
            </IconButton>
            <IconButton edge="end" aria-label="request-reply" onClick={() => {
                setOpenRequestReplyDialog(connection);
            }}>
                <CompareArrowsIcon />
            </IconButton>
            <IconButton edge="end" aria-label="delete" onClick={() => {
                if (window.confirm("Are you sure you want to delete this connection?")) {
                    pb.collection("connections").delete(connection.id as string).then((res) => {
                        NotificationService.notifySuccess("Connection deleted");
                        onDelete(connection);
                    }).catch((err) => {
                        NotificationService.notifyError("Failed to delete connection: " + err.message);
                    });
                }
            }}>
                <DeleteIcon />
            </IconButton>
        </Stack>
    }>
        <ListItemButton onClick={() => {
        }}>
            <Tooltip title={<Typography>{getTooltip()}</Typography>} >
                <ListItemIcon>
                    <Icon sx={{ fontSize: "1.5rem" }}>{getIcon()}</Icon>
                </ListItemIcon>
            </Tooltip>
            <ListItemText primary={connection.name} secondary={connection.configType} />
        </ListItemButton>
    </ListItem>;
}
