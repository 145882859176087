import * as React from 'react';
import Grid from '@mui/material/Grid';
import pb from '../services/PocketBase';
import PersonIcon from '@mui/icons-material/Person';
import GroupIcon from '@mui/icons-material/Group';
import { Card, CardHeader, CardContent, Typography, List, Container, Skeleton, Paper, Stack, TextField, CardMedia, Button, CardActions, Chip, ListItem, IconButton, CardActionArea, Divider, ListItemButton, ListItemIcon, ListItemText, CircularProgress, createTheme, ThemeProvider } from '@mui/material';
import { Component } from '../domain/Component';
import ComponentService from '../services/ComponentService';
import { useNavigate, useParams } from 'react-router-dom';
import ComponentListCard from '../components/ComponentListCard';
import ConnectionListCard from '../components/ConnectionListCard';
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import { RecordModel } from 'pocketbase';

const columns: GridColDef[] = [
    { field: 'id', headerName: 'ID', width: 200 },
    { field: 'username', headerName: 'Name', width: 200 },
    { field: 'email', headerName: 'Email', width: 200 },
];

const theme = createTheme({
    palette: {
        //primary: { main: "#3a34d2" }
        //primary: { main: "#ffffff" }
        // primary: { main: "#959ed7" },
        // secondary: { main: "#f50057" },
        // background: {
        //   default: "#1b043b",
        //   paper: "#1b043b"
        // },
        mode: 'dark',
        background: {
            default: "#404154",
            paper: "#404154"
        },
    }
    // palette: {
    //   mode: 'dark',
    // },
});

export default function IAM() {
    const { entity } = useParams();
    const navigate = useNavigate();
    const [paginationModel, setPaginationModel] = React.useState({
        page: 0,
        pageSize: 5,
    });
    const [rows, setRows] = React.useState<RecordModel[]>([]);
    const [loading, setLoading] = React.useState(true);
    const [pageInfo, setPageInfo] = React.useState<{ totalRowCount: number } | undefined>(undefined);
    const [rowCountState, setRowCountState] = React.useState(
        pageInfo?.totalRowCount || 0,
    );
    React.useEffect(() => {
        setRowCountState((prevRowCountState) =>
            pageInfo?.totalRowCount !== undefined
                ? pageInfo?.totalRowCount
                : prevRowCountState,
        );
    }, [pageInfo?.totalRowCount, setRowCountState]);
    React.useEffect(() => {
        setLoading(true);
        pb.collection('users').getList(paginationModel.page, paginationModel.pageSize).then((result) => {
            setRows(result.items);
            setLoading(false);
            setPageInfo({ totalRowCount: result.totalPages });
        });
    }, [paginationModel.page, paginationModel.pageSize]);


    return <Grid container height={"100%"}>
        <Grid item sm={12} md={3} lg={3}>
            <List>
                <ListItemButton selected={entity === "users" || !entity} onClick={() => {
                    navigate("/config/iam/users");
                }}>
                    <ListItemIcon>
                        <PersonIcon />
                    </ListItemIcon>
                    <ListItemText primary="Users" />
                </ListItemButton>
                <ListItemButton selected={entity === "teams"} onClick={() => {
                    navigate("/config/iam/teams");
                }}>
                    <ListItemIcon>
                        <GroupIcon />
                    </ListItemIcon>
                    <ListItemText primary="Teams" />
                </ListItemButton>
            </List>
        </Grid>
        <Grid item sm={12} md={9} lg={9} sx={{ display: "flex", flexDirection: "column" }}>
            <ThemeProvider theme={theme}>
                <DataGrid
                    sx={{
                        backgroundColor: "#404154",
                    }}
                    rows={rows}
                    columns={columns}
                    rowCount={rowCountState}
                    loading={loading}
                    pageSizeOptions={[5]}
                    paginationModel={paginationModel}
                    paginationMode="server"
                    onPaginationModelChange={setPaginationModel}
                />
            </ThemeProvider>
        </Grid>
    </Grid>
}
