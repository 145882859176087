import { CommonOptions, ListResult, RecordListOptions, RecordModel, RecordOptions, SendOptions } from "pocketbase";
import pb from "./PocketBase";
import NATSService from "./NATSService";
import { MsgHdrsImpl } from "nats.ws";

interface IAPIService {
    getOne<T = RecordModel>(collection: string, id: string, options?: RecordOptions, delegateID?: string): Promise<T>;
    getList<T = RecordModel>(collection: string, page?: number, perPage?: number, options?: RecordListOptions, delegateID?: string): Promise<ListResult<T>>;
    delete(collection: string, id: string, options?: CommonOptions, delegateID?: string): Promise<boolean>;
    create<T = RecordModel>(collection: string, bodyParams?: {
        [key: string]: any;
    } | FormData, options?: RecordOptions, delegateID?: string): Promise<T>;
    send<T = any>(path: string, options: SendOptions, delegateID?: string): Promise<T>;
}

const DELEGATE_API_TIMEOUT = 5000;

function normalizeParams(params:any) {
    Object.keys(params).forEach((k) => {
        if (params[k] === undefined) {
            delete params[k];
            return;
        }
        params[k] = params[k] + "";
    });

    return params;
}

const APIService: IAPIService = {
    getOne(collection: string, id: string, options?: RecordOptions, delegateID?: string) {
        if (!delegateID) {
            return pb.collection(collection).getOne(id, options);
        }

        const h = new MsgHdrsImpl();
        if (options) {
            h.set("X-Query-Params", JSON.stringify(normalizeParams({
                ...options
            })));
        }

        return NATSService.getNATSConnection().then((conn) => {
            return conn.request(`delegates.${delegateID}.rpc.get./api/collections/${collection}/records/${id}`, undefined, { 
                timeout: DELEGATE_API_TIMEOUT,
                headers: h
             }).then((msg) => {
                console.log(msg);
                if (!msg.headers) {
                   return Promise.reject("No headers"); 
                }
                if (msg.headers.get("X-Status-Code") !== "200") {
                    return Promise.reject({
                        message: "Invalid status code: " + msg.headers.get("X-Status-Code") + " - " + new TextDecoder().decode(msg.data),
                        code: msg.headers.get("X-Status-Code")
                    });
                }
                try {
                    return JSON.parse(new TextDecoder().decode(msg.data));
                } catch (error) {
                    console.error(error);
                    console.error(new TextDecoder().decode(msg.data));
                    return Promise.reject(error);
                }
            });
        });
    },
    getList(collection: string, page?: number, perPage?: number, options?: RecordListOptions, delegateID?: string) {
        if (!delegateID) {
            return pb.collection(collection).getList(page, perPage, options);
        }

        const h = new MsgHdrsImpl();
        h.set("X-Query-Params", JSON.stringify(normalizeParams({
            page: page,
            perPage: perPage,
            ...options
        })));
        return NATSService.getNATSConnection().then((conn) => {
            return conn.request(`delegates.${delegateID}.rpc.get./api/collections/${collection}/records`, undefined, { 
                timeout: DELEGATE_API_TIMEOUT,
                headers: h
             }).then((msg) => {
                if (!msg.headers) {
                   return Promise.reject("No headers"); 
                }
                if (msg.headers.get("X-Status-Code") !== "200") {
                    return Promise.reject({
                        message: "Invalid status code: " + msg.headers.get("X-Status-Code") + " - " + new TextDecoder().decode(msg.data),
                        code: msg.headers.get("X-Status-Code")
                    });
                }
                return JSON.parse(new TextDecoder().decode(msg.data));
            });
        });
    },
    delete(collection: string, id: string, options?: CommonOptions, delegateID?: string) {
        if (!delegateID) {
            return pb.collection(collection).delete(id, options);
        }

        const h = new MsgHdrsImpl();
        if (options) {
            h.set("X-Query-Params", JSON.stringify(normalizeParams({
                ...options
            })));
        }

        return NATSService.getNATSConnection().then((conn) => {
            return conn.request(`delegates.${delegateID}.rpc.delete./api/collections/${collection}/records/${id}`, undefined, { 
                timeout: DELEGATE_API_TIMEOUT,
                headers: h
             }).then((msg) => {
                if (!msg.headers) {
                   return Promise.reject("No headers"); 
                }
                if (msg.headers.get("X-Status-Code") !== "204") {
                    return Promise.reject({
                        message: "Invalid status code: " + msg.headers.get("X-Status-Code") + " - " + new TextDecoder().decode(msg.data),
                        code: msg.headers.get("X-Status-Code")
                    });
                }
                return Promise.resolve(true);
            });
        });
    },
    create<T = RecordModel>(collection: string, bodyParams?: {
        [key: string]: any;
    } | FormData, options?: RecordOptions, delegateID?: string) {
        if (!delegateID) {
            return pb.collection(collection).create(bodyParams, options);
        }

        const h = new MsgHdrsImpl();
        h.set("Content-Type", "application/json");
        if (options) {
            h.set("X-Query-Params", JSON.stringify(normalizeParams({
                ...options
            })));
        }

        return NATSService.getNATSConnection().then((conn) => {
            return conn.request(`delegates.${delegateID}.rpc.post./api/collections/${collection}/records`, JSON.stringify(bodyParams), { 
                timeout: DELEGATE_API_TIMEOUT,
                headers: h
             }).then((msg) => {
                if (!msg.headers) {
                   return Promise.reject("No headers"); 
                }
                if (msg.headers.get("X-Status-Code") !== "200") {
                    return Promise.reject({
                        message: "Invalid status code: " + msg.headers.get("X-Status-Code") + " - " + new TextDecoder().decode(msg.data),
                        code: msg.headers.get("X-Status-Code")
                    });
                }
                return JSON.parse(new TextDecoder().decode(msg.data));
            });
        });
    },
    send<T = any>(path: string, options: SendOptions, delegateID?: string) {
        if (!delegateID) {
            return pb.send(path, options);
        }

        const h = new MsgHdrsImpl();
        if (options && options.headers) {
            Object.keys(options.headers).forEach((k) => {
                h.set(k, options.headers?.[k] as string);
            });
        }
        if (options && options.query) {
            h.set("X-Query-Params", JSON.stringify(normalizeParams({
                ...options.query
            })));
        }

        return NATSService.getNATSConnection().then((conn) => {
            return conn.request(`delegates.${delegateID}.rpc.${options.method ? options.method.toLowerCase() : "get"}.${path}`, options.body ? JSON.stringify(options.body) : undefined, { 
                timeout: DELEGATE_API_TIMEOUT,
                headers: h
             }).then((msg) => {
                if (!msg.headers) {
                   return Promise.reject("No headers"); 
                }
                if (msg.headers.get("X-Status-Code") !== "200") {
                    return Promise.reject({
                        message: "Invalid status code: " + msg.headers.get("X-Status-Code") + " - " + new TextDecoder().decode(msg.data),
                        code: msg.headers.get("X-Status-Code")
                    });
                }
                return JSON.parse(new TextDecoder().decode(msg.data));
            });
        });
    },
};
export default APIService;
