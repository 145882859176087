import { Card, CardContent, CardHeader, CardMedia, Typography } from "@mui/material";
import { useNavigate } from 'react-router-dom';
import Icon from '@mui/material/Icon';



interface TileCardProps {
    tile: TileProps;
}
interface TileProps {
    name: string;
    icon?: string;
    description?: string;
    route?: string;
    url?: string;
}

export default function TileCard({tile}: TileCardProps) {

    const nav = useNavigate();

    return  <Card style={{ height: "100%" }} elevation={0}
    sx={{
        minWidth: 320,
        position: 'relative',
        boxShadow: '0 8px 24px 0 rgba(0,0,0,0.12)',
        overflow: 'visible',
        borderRadius: '1.5rem',
        transition: '0.4s',
        '&:hover': {
            transform: 'translateY(-2px)',
            backgroundColor: '#70718d'
        },
        '&:before': {
            content: '""',
            position: 'absolute',
            zIndex: 0,
            display: 'block',
            width: '100%',
            bottom: -1,
            height: '100%',
            borderRadius: '1.5rem',
        },
        cursor: "pointer"
    }}
    onClick={() => {
        if (tile.route === undefined && tile.url === undefined) {
            return;
        }
        if (tile.url) {
            if (tile.url.startsWith("http://") || tile.url.startsWith("https://")) {
                window.open(tile.url, "_blank");
                return;
            }
            window.location.href = (process.env.NODE_ENV === "development" ? "http://127.0.0.1:8090" : "") +  tile.url;
        }
        if (tile.route) {
            nav("/"+tile.route);
        }
    }} >
    <div style={{
        height: "100%",
        display: "flex",
        flexDirection: "column",
        justifyContent: "flex-start"
    }}>
        <CardHeader
            action={
                undefined
            }
            title={tile.name}
        />
        <CardMedia
            sx={{ height: 100 }}
            children={<Icon sx={{fontSize: "5rem", height: "100%",width: "100%"}}>{tile.icon}</Icon>}
            title="logo"
            style={{
                backgroundSize: "contain"
            }}
        />
        <CardContent sx={{ flexGrow: 1 }}>
            {tile.description ? <Typography variant="body2" color="text.secondary">
                {tile.description}
            </Typography> : undefined}
        </CardContent>
    </div>
</Card>
}