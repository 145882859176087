import { RecordModel } from "pocketbase";
import LabelService from "./LabelService";
import { Config } from "../domain/Config";

const ConfigService = {
    transformRecord: (record: RecordModel) => {
        let res: Config = {
            id: record.id,
            secret: record["secret"],
            type: record["type"],
            config: record["config"],
            configType: record["config_type"],
            labels: record.expand && record.expand["labels"] ? (record.expand["labels"] as RecordModel[]).map(l => LabelService.transformRecord(l)) : undefined,
        }
        return res;
    },
}

export default ConfigService;