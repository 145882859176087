import React from 'react';
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import ErrorPage from './pages/ErrorPage';
import { Pages } from './nav/ListItems';
import { ProtectedRoute } from './services/auth/ProtectedRoute';
import SignIn from './pages/SignIn';
import { AuthProvider } from './services/auth/useAuth';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { SnackbarProvider } from 'notistack';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

const theme = createTheme({
  palette: {
    //primary: { main: "#3a34d2" }
    //primary: { main: "#ffffff" }
    // primary: { main: "#959ed7" },
    // secondary: { main: "#f50057" },
    // background: {
    //   default: "#1b043b",
    //   paper: "#1b043b"
    // },
    mode: 'dark',
    background: {
        default: "transparent",
        paper: "transparent"
      },
  }
  // palette: {
  //   mode: 'dark',
  // },
});
root.render(
  <React.StrictMode>
    <SnackbarProvider />
    <BrowserRouter>
      <AuthProvider>
        <ThemeProvider theme={theme}>
          <Routes>
            <Route path="/"
              errorElement={<ErrorPage />}
              element={<ProtectedRoute children={<App />}></ProtectedRoute>}>

              {Pages.map((navItem, i) => {
                return <React.Fragment key={i} >
                  {navItem.routes.map((route) => {
                    return <Route key={route} path={route} element={navItem.page}>
                    </Route>;
                  })}
                </React.Fragment>;
              })}
              <Route
                path="/"
                element={
                  <Navigate replace to={"/" + Pages[0].routes[0]} />
                }
              />
            </Route>

            <Route path="/login" element={<SignIn />}>
            </Route>
          </Routes>
        </ThemeProvider>
      </AuthProvider>
    </BrowserRouter>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
