import { Avatar, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, FormControl, Icon, List, ListItem, ListItemAvatar, ListItemText, ListSubheader, TextField, Typography } from "@mui/material";
import * as React from 'react';
import {
    AckPolicy,
    DeliverPolicy,
    KvEntry,
    QueuedIterator,
    nanos,
} from "nats.ws";
import Ajv from "ajv";
import addFormats from "ajv-formats";
import JSONSchemaService from "../services/JSONSchemaService";
import { dataSetsFromJSON } from "../domain/DataSet";
import NATSService from "../services/NATSService";
import { LogEntry } from "../domain/LogEntry";
import { ConnectionInfo } from "../domain/ConnectionInfo";
import { Connection } from "../domain/Connection";
import { Editor } from "@monaco-editor/react";
import NotificationService from "../services/NotificationService";


interface RequestReplyDialogProps {
    connection: Connection | undefined;
    open: boolean;
    onClose: () => void;
}

function tryJSONForm(val: string) {
    try {
      const res = JSON.parse(val);
      return JSON.stringify(res, null, 2)
    } catch {
        return val;
    }
  }

export default function RequestReplyDialog({ connection, open, onClose }: RequestReplyDialogProps) {
    const [connectionInfos, setConnectionInfos] = React.useState<{ [instanceID: string]: ConnectionInfo }>({});
    const [kvWatcher, setKVWatcher] = React.useState<QueuedIterator<KvEntry> | undefined>(undefined);
    const [subject, setSubject] = React.useState<string>("");
    const [request, setRequest] = React.useState<string>("");
    const [response, setResponse] = React.useState<string>("");

    const updateInfo = () => {
        if (!connection) {
            return;
        }
        NATSService.getConnectionInfoKV()
            .then((kv) => {
                // TODO: convert to watch and do something on all initial values
                return kv.watch({
                    key: `connection_infos.${connection.id}.${connection.type}.${connection.configType}.>`,
                    initializedFn: () => {

                    }
                });
                //return kv.get(`connection_infos.${connection.id}.${connection.type}.${connection.configType}`);
            })
            .then(async (kvIterator) => {
                if (kvWatcher) {
                    kvWatcher.stop();
                }
                setKVWatcher(kvIterator);
                for await (const entry of kvIterator) {
                    if (entry) {
                        const ci = JSON.parse(new TextDecoder().decode(entry.value));
                        ci.time = new Date(ci.time);
                        switch (entry.operation) {
                            case "PUT":
                                setConnectionInfos((prev) => {
                                    return {
                                        ...prev,
                                        [ci.instanceID]: ci
                                    };
                                });
                                break;
                            case "DEL":
                                setConnectionInfos((prev) => {
                                    const newPrev = { ...prev };
                                    delete newPrev[ci.instanceID];
                                    return newPrev;
                                });
                                break;
                            case "PURGE":
                                setConnectionInfos((prev) => {
                                    const newPrev = { ...prev };
                                    delete newPrev[ci.instanceID];
                                    return newPrev;
                                });
                                break;
                            default:
                                break;
                        }
                    }
                }
            });
    };

    React.useEffect(() => {
        setSubject("connections.rpc.all." + connection?.id);
        if (!open) {
            if (kvWatcher) {
                kvWatcher.stop();
            }
            return;
        }
        updateInfo();
        return () => {
            if (kvWatcher) {
                kvWatcher.stop();
            }
        }
    }, [connection, open]);

    const handleClose = () => {
        onClose();
    };

    return <Dialog onClose={handleClose} open={open} fullWidth={true}>
        <DialogTitle>Request / Reply for {connection?.name} - {connection?.id}</DialogTitle>
        <DialogContent>

            <FormControl fullWidth sx={{ marginBottom: "40px" }}>
                <TextField
                    id="subject"
                    value={subject}
                    label="Subject"
                    name="subject"
                    variant="standard"
                    onChange={(ev: any) => {
                        setSubject(ev.target.value);
                    }}
                />
            </FormControl>
            <Typography variant="h6" sx={{ marginBottom: "10px" }}>Request</Typography>
            <Editor
                key={connection?.id + "request"}
                beforeMount={(monaco) => {
                }}

                height="300px"
                language="json"
                theme="vs-dark"
                value={request}
                options={{
                    // inlineSuggest: true,
                    // fontSize: "16px",
                    formatOnType: true,
                    // autoClosingBrackets: true,
                    minimap: { scale: 10 }
                }}
                onChange={(value) => {
                    setRequest(value as string);
                }}
            />
            <Button variant="contained" onClick={() => {
                NATSService.getNATSConnection()
                .then((nc) => {
                    nc.request(subject, request, {
                        timeout: 5000,
                    })
                    .then((resp) => {
                        setResponse(new TextDecoder().decode(resp.data));
                    })
                    .catch((err) => {
                        NotificationService.notifyError("Failed to send request: " + err.message);
                        console.log(err);
                    });
                })
            }} sx={{ marginTop: "40px" }}>Send</Button>

            <Typography variant="h6" sx={{ marginBottom: "10px" }}>Reply</Typography>

            <Editor
                key={connection?.id + "reply"}
                beforeMount={(monaco) => {
                }}
                height="300px"
                language="json"
                theme="vs-dark"
                value={tryJSONForm(response)}
                options={{
                    // inlineSuggest: true,
                    // fontSize: "16px",
                    formatOnType: true,
                    // autoClosingBrackets: true,
                    minimap: { scale: 10 },
                    readOnly: true,
                }}
                onChange={(value) => {
                    setResponse(value as string);
                }}
            />

            <List sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }} subheader={<ListSubheader>Available instances</ListSubheader>}>
                {connectionInfos ? Object.keys(connectionInfos).map((k, i) => {
                    const w = connectionInfos[k];
                    return <ListItem key={i}>
                        <ListItemAvatar>
                            <Avatar>
                                <Icon sx={{ fontSize: "1.5rem" }}>{
                                    (w.active === true ? "play_arrow" : "") +
                                    (w.active === false ? "stop" : "")
                                }</Icon>
                            </Avatar>
                        </ListItemAvatar>
                        <ListItemText primary={w.instanceID} secondary={w.time.toLocaleString()} />
                    </ListItem>
                }) : undefined}
            </List>
        </DialogContent>
        <DialogActions>
        </DialogActions>
    </Dialog>
}