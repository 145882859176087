import { RecordModel } from "pocketbase";
import LabelService from "./LabelService";
import { Connection } from "../domain/Connection";
import ComponentService from "./ComponentService";
import CredentialService from "./CredentialService";
import ConfigService from "./ConfigService";

const ConnectionService = {
    transformRecord: (record: RecordModel) => {
        let res: Connection = {
            id: record.id,
            name: record["name"],
            description: record["description"],
            type: record["type"],
            componentID: record["component"],
            configID: record["config"],
            configType: record["config_type"],
            credentialsID: record["credentials"],
            scopes: record["scopes"],
            component: record.expand && record.expand["component"] ? ComponentService.transformRecord(record.expand["component"] as RecordModel) : undefined,
            credentials: record.expand && record.expand["credentials"] ? CredentialService.transformRecord(record.expand["credentials"] as RecordModel) : undefined,
            labels: record.expand && record.expand["labels"] ? (record.expand["labels"] as RecordModel[]).map(l => LabelService.transformRecord(l)) : undefined,
            config: record.expand && record.expand["config"] ? ConfigService.transformRecord(record.expand["config"] as RecordModel) : undefined,
        }
        return res;
    },
}

export default ConnectionService;