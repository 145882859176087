import { RecordModel } from "pocketbase";
import { JSONSchema } from "../domain/JSONSchema";
import pb from "./PocketBase";

const JSONSchemaService = {
    transformRecord: (record: RecordModel) => {
        let res: JSONSchema = {
            id: record.id,
            schemaID: record["schema_id"],
            jsonSchema: record["json_schema"],
        }
        return res;
    },
    getJSONSchema: (schemaID: string): Promise<JSONSchema |undefined> => {
        return pb.collection("json_schemas").getList(1, 1, {
            filter: "schema_id = '" + schemaID + "'"
        })
            .then((res) => {
                if (res.items.length > 0) {
                    return JSONSchemaService.transformRecord(res.items[0]);
                } else {
                    return undefined;
                }
            });
    },
}

export default JSONSchemaService;