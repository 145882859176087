import { Autocomplete, Card, CardContent, CardHeader, FormControl, IconButton, TextField, Typography } from "@mui/material";
import SaveIcon from '@mui/icons-material/Save';
import { Connection } from "../domain/Connection";
import { ConnectionTemplate } from "../domain/ConnectionTemplate";
import { Editor } from "@monaco-editor/react";
import React from "react";
import { Config } from "../domain/Config";


interface ComponentListCardProps {
    id: string;
    type: string;
    newConnection: Connection;
    onChange: (ev: any) => void;
    onValidationChange: (valid: boolean) => void;
    connectionTemplate: ConnectionTemplate;
}

export default function ConnectionConfigFormCard({ id, type, newConnection, onChange, onValidationChange, connectionTemplate }: ComponentListCardProps) {

    return <Card style={{ height: "100%" }} elevation={0}
        sx={{
            minWidth: 320,
            position: 'relative',
            boxShadow: '0 8px 24px 0 rgba(0,0,0,0.12)',
            overflow: 'visible',
            borderRadius: '1.5rem',
            display: "flex",
            flexDirection: "column"
        }}>
        <CardHeader
            title={
                <Typography>New config for {connectionTemplate.id ? connectionTemplate.name : "custom"} connection</Typography>
            }
        />
        <CardContent sx={{ flexGrow: 1, display: "flex", flexDirection: "column" }}>
            {connectionTemplate.id === undefined ? <FormControl fullWidth sx={{ marginBottom: "40px" }}>
                <TextField
                    required
                    id="conn-type"
                    value={newConnection.configType}
                    label="Config Type"
                    variant="standard"
                    name="configType"
                    onChange={(ev) => {
                        onChange(ev);
                    }}
                />
            </FormControl> : undefined}
            {connectionTemplate.id === undefined ? <React.Fragment>
                {newConnection && newConnection.config ? <Editor
                    key={"undefined"}
                    beforeMount={(monaco) => {
                        onValidationChange(true); // assume valid json was provided as default value
                        monaco.editor.onDidChangeMarkers((e) => {
                            if (!newConnection || !newConnection.config) {
                                return;
                            }
                            var hasMarkers = false;
                            for (let index = 0; index < e.length; index++) {
                                const element = e[index];
                                const markers = monaco.editor.getModelMarkers({ resource: element });
                                if (markers.length > 0) {
                                    hasMarkers = true;
                                }
                            }
                            (newConnection.config as any)._valid = !hasMarkers;
                            onValidationChange((newConnection.config as any)._valid === true);
                        });
                    }}
                    height="100%"
                    language="json"
                    theme="vs-dark"
                    value={newConnection.config.config}
                    options={{
                        // inlineSuggest: true,
                        // fontSize: "16px",
                        formatOnType: true,
                        // autoClosingBrackets: true,
                        minimap: { scale: 10 }
                    }}
                    onChange={(value) => {
                        onChange({
                            target: {
                                name: "config",
                                value: value,
                            }
                        });
                    }}
                /> : undefined}
            </React.Fragment> : undefined}
            {connectionTemplate.id
                && newConnection
                && newConnection.config
                && connectionTemplate.configSchema ? <React.Fragment key={connectionTemplate.id}>
                <Typography variant="h6" sx={{ marginBottom: "10px" }}>{connectionTemplate.name}</Typography>
                <Editor
                    key={connectionTemplate.id}
                    beforeMount={(monaco) => {
                        if (connectionTemplate.configSchema === undefined) {
                            return;
                        }
                        monaco.languages.json.jsonDefaults.setDiagnosticsOptions({
                            validate: true,
                            schemas: [{
                                uri: connectionTemplate.configSchema.id as string, // id of the first schema
                                fileMatch: [connectionTemplate.configSchema.name], // associate with our model
                                schema: connectionTemplate.configSchema.configSchema
                            }]
                        });
                        monaco.editor.onDidChangeMarkers((e) => {
                            if (!newConnection || !newConnection.config) {
                                return;
                            }
                            var hasMarkers = false;
                            for (let index = 0; index < e.length; index++) {
                                const element = e[index];
                                const markers = monaco.editor.getModelMarkers({ resource: element });
                                if (markers.length > 0) {
                                    hasMarkers = true;
                                }
                                console.log("markers", markers);
                            }
                            (newConnection.config as any)._valid = !hasMarkers;
                            onValidationChange((newConnection.config as any)._valid === true);
                        });
                    }}

                    height="100%"
                    language="json"
                    theme="vs-dark"
                    defaultPath={connectionTemplate.configSchema.name}
                    value={(newConnection.config as Config).config}
                    options={{
                        // inlineSuggest: true,
                        // fontSize: "16px",
                        formatOnType: true,
                        // autoClosingBrackets: true,
                        minimap: { scale: 10 }
                    }}
                    onChange={(value) => {
                        onChange({
                            target: {
                                name: "config",
                                value: value,
                            }
                        });
                    }}
                />
            </React.Fragment> : undefined}
        </CardContent>
    </Card>
}