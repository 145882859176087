import { RecordModel } from "pocketbase";
import { WorkerTemplate } from "../domain/WorkerTemplate";

const WorkerTemplateService = {
    transformRecord: (record: RecordModel) => {
        let res: WorkerTemplate = {
            id: record.id,
            name: record["name"],
            version: record["version"],
            type: record["type"],
            scheduler: record["scheduler"],
        }
        return res;
    },
}

export default WorkerTemplateService;