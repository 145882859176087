import { RecordModel } from "pocketbase";
import { Tile } from "../domain/Tile";

const TileService = {
    transformRecord: (record: RecordModel) => {
        let res : Tile = {
            id: record.id,
            name: record["name"],
            description: record["description"],
            icon: record["icon"],
            url: record["url"],
        }
        return res;
    },
}

export default TileService;