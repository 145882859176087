import { RecordModel } from "pocketbase";
import { Label } from "../domain/Label";

const LabelService = {
    transformRecord: (record: RecordModel) => {
        let res : Label = {
            id: record.id,
            name: record["name"],
            value: record["value"]
        }
        return res;
    },
}

export default LabelService;