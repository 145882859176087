import { Dialog, DialogContent, DialogContentText, DialogTitle } from "@mui/material";
import * as React from 'react';
import {
    AckPolicy,
    ConsumerMessages,
    DeliverPolicy,
    nanos,
} from "nats.ws";
import ReactApexChart from "react-apexcharts";
import { ApexOptions } from "apexcharts";
import Ajv from "ajv";
import addFormats from "ajv-formats";
import JSONSchemaService from "../services/JSONSchemaService";
import { dataSetsFromJSON } from "../domain/DataSet";
import NATSService from "../services/NATSService";


interface LiveDataDialogProps {
    id: string;
    name: string;
    open: boolean;
    onClose: () => void;
}


const chartOptions: ApexOptions = {
    chart: {
        id: 'realtime',
        height: 350,
        type: 'line',
        animations: {
            enabled: false,
            easing: 'linear',
            dynamicAnimation: {
                speed: 1000
            }
        },
        toolbar: {
            show: false
        },
        zoom: {
            enabled: false
        }
    },
    dataLabels: {
        enabled: false
    },
    stroke: {
        curve: 'smooth',
        width: 2
    },
    title: {
        text: '',
        align: 'left'
    },
    markers: {
        size: 4,

    },
    xaxis: {
        type: 'datetime'
    },
    yaxis: {
        labels: {
            formatter: function (val: number) {
                return val.toFixed(2);
            }
        }
    },
    legend: {
        show: true
    },
    tooltip: {
        x: {
            format: 'dd/MM/yy HH:mm:ss' // fff as milliseconds does not work
        },
    },
};

const MAX_DATA_POINTS = 20;

export default function LiveDataDialog({ id, name, open, onClose }: LiveDataDialogProps) {
    const [series, setSeries] = React.useState<{ [name: string]: { x: number, y: number }[] }>({});

    React.useEffect(() => {
        if (!open) {
            return () => { };
        }
        setSeries({});
        let consumerMessages: ConsumerMessages | undefined | null = undefined;

        NATSService.getNATSConnection()
            .then((nc) => {
                console.log("connected to NATS");
                return Promise.all([nc.jetstreamManager().then((jsm) => {
                    return jsm.consumers.add("data", {
                        ack_policy: AckPolicy.None,
                        name: id + new Date().getTime(),
                        inactive_threshold: nanos(2 * 60 * 1000), // given in nanos? => 2min
                        deliver_policy: DeliverPolicy.New,
                        filter_subject: `data.connections.*.${id}.>`,
                    });
                }), nc.jetstream()]);
            }).then((res) => {
                return Promise.all([res[1].consumers.get("data", res[0].config.name), JSONSchemaService.getJSONSchema("https://iot-data.cloud/basic.data.schema")]);
            }).then((res) => {
                const c = res[0];
                const schema = res[1];
                return c.consume().then(async (messages) => {
                    if (consumerMessages !== undefined && consumerMessages !== null) {
                        consumerMessages.close();                        
                    }
                    if (consumerMessages === null) {
                        messages.close();
                        return;
                    }
                    consumerMessages = messages;
                    const ajv = new Ajv();
                    addFormats(ajv);

                    for await (const m of messages) {
                        try {
                            const data = JSON.parse(new TextDecoder().decode(m.data));
                            const valid = ajv.validate(schema?.jsonSchema, data);
                            if (!valid) {
                                console.error("invalid data received: ", ajv.errors);
                                continue;
                            }

                            const dataSets = dataSetsFromJSON(data);

                            setSeries((previousSeries: { [name: string]: { x: number, y: number }[] }) => {
                                dataSets.forEach((ds) => {
                                    Object.keys(ds.data).forEach((key) => {
                                        if (previousSeries[ds.name + "-" + key] === undefined) {
                                            previousSeries[ds.name + "-" + key] = [];
                                        }
                                        const t = typeof ds.data[key].value;
                                        switch (t) {
                                            case "number":
                                                previousSeries[ds.name + "-" + key] = previousSeries[ds.name + "-" + key].slice();
                                                previousSeries[ds.name + "-" + key].push({ x: ds.time.getTime(), y: ds.data[key].value as number });
                                                if (previousSeries[ds.name + "-" + key].length > MAX_DATA_POINTS) {
                                                    previousSeries[ds.name + "-" + key].shift();
                                                }
                                                return;

                                            case "boolean":
                                                previousSeries[ds.name + "-" + key].push({ x: ds.time.getTime(), y: (ds.data[key].value as boolean) ? 1 : 0 });
                                                if (previousSeries[ds.name + "-" + key].length > MAX_DATA_POINTS) {
                                                    previousSeries[ds.name + "-" + key].shift();
                                                }
                                                return;
                                            default:
                                                // Do nothing on strings, etc
                                                return;
                                        }

                                    });
                                });
                                return { ...previousSeries };
                            });

                        } catch (error) {
                            console.log(error);
                        }
                    }
                });
            }).catch((err) => {
                console.error(err);
            });

        return () => {
            if (consumerMessages !== undefined && consumerMessages !== null) {
                consumerMessages.close();                        
            }
            consumerMessages = null;
        }
    }, [open, id])

    const handleClose = () => {
        onClose();
    };

    const getSeries = () => {
        return Object.keys(series).map((s) => {
            return {
                name: s,
                data: series[s],
            };
        });
    }

    return <Dialog onClose={handleClose} open={open} fullWidth={true}>
        <DialogTitle>Live data of {name}</DialogTitle>
        <DialogContent>
            <DialogContentText>
            </DialogContentText>
            <ReactApexChart options={chartOptions} series={getSeries()} type="line" height={350} />
        </DialogContent>
    </Dialog>
}