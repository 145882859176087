import SettingService from "./SettingService";
import {
    connect,
    credsAuthenticator,
    KV,
    NatsConnection,
} from "nats.ws";

const NATSService : {
    nc: NatsConnection | undefined;
    connectionInfoKV: KV | undefined;
    getNATSConnection: () => Promise<NatsConnection>;
    getConnectionInfoKV: () => Promise<KV>;
} = {
    nc: undefined,
    connectionInfoKV: undefined,
    getNATSConnection: () => {
        if (NATSService.nc === undefined) {
            return SettingService.getSettings(undefined /* always use global NATS connection*/).then((res) => {
                if (res === undefined) {
                    return Promise.reject("No settings found");
                }
                var host = res["nats_websocket_host"].value;
    
                if (host === undefined || host === "") {
                    host = window.location.hostname;
                }
    
                return connect({
                    servers: [res["nats_websocket_protocol"].value + "://" + host + ":" + res["nats_websocket_port"].value],
                    authenticator: credsAuthenticator(new TextEncoder().encode(res["nats_creds"].value)),
                    maxReconnectAttempts: -1,
                })
            }).then((nc) => {
                NATSService.nc = nc;
                return nc;
            });
        }

        return Promise.resolve(NATSService.nc as NatsConnection);
    },
    getConnectionInfoKV: () => {
        if (NATSService.connectionInfoKV === undefined) {
            return NATSService.getNATSConnection().then((nc) => {
                const js = nc.jetstream();
                return js.views.kv("connection_infos");
            })
            .then((kv) => {
                NATSService.connectionInfoKV = kv;
                return kv;
            });
        }

        return Promise.resolve(NATSService.connectionInfoKV as KV);
    },
}
export default NATSService;
