import CssBaseline from '@mui/material/CssBaseline';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import Link from '@mui/material/Link';
import { Pages } from './nav/ListItems';

import Nav from './components/Nav';
import { Outlet } from 'react-router-dom';

function Copyright(props: any) {
  return (
    <Typography variant="body2" color="text.secondary" align="center" {...props}>
      {'Copyright © '}
      <Link color="inherit" href="/">
        iot-data.cloud
      </Link>{' '}
      {"2022 - " + new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}


function DashboardContent() {
  return (
    <Box sx={{ display: 'flex' }}>
      <CssBaseline />
      <Nav mainNavItems={Pages} />
      <Box
        component="main"
        sx={{
          // backgroundColor: (theme) =>
          //   theme.palette.mode === 'light'
          //     ? "#fff"
          //     : theme.palette.grey[900],
          flexGrow: 1,
          height: '100vh',
          overflow: 'auto',
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <Toolbar />
        <Container maxWidth={false} sx={{ flexGrow: 1 }}>
          <Outlet />
        </Container>
        <Copyright sx={{ pt: 4 }} />
      </Box>
    </Box>
  );
}

export default function App() {

  // Initialize stores ...

  return <DashboardContent />;
}